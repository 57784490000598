import React, { useState, useRef, useEffect } from 'react';
import { Grid, Theme, InputAdornment, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionId, getPersistedDifferenceComments, inspection, getFocusedDifferenceId } from 'store';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import GVDifferenceButtonSet from 'components/lib/GVDifferenceButtonSet/GVDifferenceButtonSet';
import { updateDifference } from 'store/request/differences/actions';
import { patchGroup } from 'store/request/difference-group/actions';
import { Difference } from 'store/request/differences/types';
import { Warning } from '@mui/icons-material';
import { useTracker } from '../../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';

interface DifferenceElementProps {
  difference: Difference;
  numberOfDifference: number;
  handleToggleComment: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, open: boolean) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  buttons: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  cautionLabel: {
    paddingLeft: '48px',
  },
  cautionButton: {
    color: 'rgba(255, 152, 0, 0.87)',
    height: theme.spacing(2),
  },
  tooltip: {
    maxWidth: 266,
  },
  cautionAdornment: {
    marginTop: '-17px',
    marginLeft: theme.spacing(0.8),
  },
}));

const DifferenceComments = ({ difference, numberOfDifference, handleToggleComment }: DifferenceElementProps) => {
  const tracker = useTracker();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  let savedComment = difference.comment || '';
  const inspectionId = useSelector(getInspectionId);
  const differenceComments = useSelector(getPersistedDifferenceComments);
  const focusedDifferenceId = useSelector(getFocusedDifferenceId);
  const persistedComment = differenceComments[difference.id] ?? '';
  const inputRef = useRef<HTMLInputElement>(null);
  const commentRef = useRef<string>(''); // used for accessing input on component unmount
  const [comment, setComment] = useState<string>(persistedComment || savedComment);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [isCommentUnsaved, setIsCommentUnsaved] = useState<boolean>(false);

  useEffect(() => {
    commentRef.current = comment;
  }, [comment]);

  useEffect(() => {
    const commentIsUnsaved = comment !== savedComment;
    setIsCommentUnsaved(commentIsUnsaved);
    if (commentIsUnsaved) setShowButtons(true);

    if (focusedDifferenceId === difference.id) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        inspection.actions.setPersistedComment({
          diffId: difference.id,
          comment: commentRef.current,
        }),
      );
    };
  }, [dispatch, difference.id]);

  useEffect(() => {
    setComment(persistedComment || savedComment || '');
  }, [savedComment, persistedComment]);

  const handleResetComment = (e: React.MouseEvent) => {
    e.stopPropagation();
    setComment(savedComment);
    if (inputRef.current) {
      inputRef.current.blur();
    }
    setShowButtons(false);
  };

  const handleSaveComment = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (difference.groupId) {
      dispatch(patchGroup(inspectionId, difference.groupId, { comment }));
    } else {
      dispatch(updateDifference(difference.id, { comment }));
    }
    dispatch(
      inspection.actions.setPersistedComment({
        diffId: difference.id,
        comment: commentRef.current,
      }),
    );
    setIsCommentUnsaved(false);

    tracker.track({
      name: 'difference-commented',
      data: {
        differenceComment: comment,
        differenceId: difference.groupId ? difference.groupId : difference.id,
        differenceType: difference.type,
        groupType: difference.groupId ? 'group' : 'single',
      },
    });

    if (inputRef.current) {
      inputRef.current.blur();
    }

    e.stopPropagation();
    savedComment = comment;
    setShowButtons(false);

    if (comment === '') {
      handleToggleComment(e, false);
    }
  };

  const handleCommentKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSaveComment(e);
    } else if (e.key === 'Escape') {
      setComment(savedComment);
      if (inputRef.current) {
        inputRef.current.blur();
      }
      setShowButtons(false);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
    setShowButtons(true);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // place cursor at end instead of beginning of input on autofocus
    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
  };

  let textFieldInputProps;
  if (isCommentUnsaved) {
    textFieldInputProps = {
      InputLabelProps: { className: classes.cautionLabel },
      InputProps: {
        ref: inputRef,
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip
              title="CAUTION: Unsaved changes will not appear on your report. Please save this change."
              classes={{ tooltip: classes.tooltip }}
            >
              <Warning className={`${classes.cautionButton} ${classes.cautionAdornment}`} />
            </Tooltip>
          </InputAdornment>
        ),
      },
    };
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item />
      <GVTextField
        {...textFieldInputProps}
        fullWidth
        multiline
        value={comment}
        label="Add a comment"
        inputProps={{ ref: inputRef }}
        id={`commentInput-${numberOfDifference + 1}`}
        onChange={handleOnChange}
        onKeyDown={handleCommentKeyDown}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onFocus={handleOnFocus}
      />
      {showButtons && (
        <GVDifferenceButtonSet
          handleCancel={handleResetComment}
          handleSave={handleSaveComment}
          isSubDifference={false}
          stringNumberForId={`${numberOfDifference + 1}`}
        />
      )}
    </Grid>
  );
};

export default DifferenceComments;
