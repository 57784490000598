import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCTFVersion } from './ctfVersionStore';
import { Breadcrumbs, Typography, Link, CardHeader, Paper, Grid, Tabs, Tab } from '@mui/material';
import { GVTabPanel } from 'components/common';
import InspectionBundle from './InspectionBundle';
import VersionSelector from './VersionSelector';

export const InternalPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { availableVersions, resolvedVersion, fetchAvailableVersions, fetchVersionOverrides, fetchResolvedVersion } =
    useCTFVersion();

  useEffect(() => {
    fetchAvailableVersions();
    fetchVersionOverrides();
    fetchResolvedVersion();
  }, []);

  const handleTabs = (_event: React.ChangeEvent<{}>, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
  };

  if (!Object.keys(availableVersions).length) return null;

  return (
    <>
      <Breadcrumbs sx={{ marginTop: 4, marginLeft: 4 }} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/" component={RouterLink}>
          Home
        </Link>
        <Link underline="hover" color="inherit" to="/internal" component={RouterLink}>
          Internal
        </Link>
        <Typography variant="caption" color="text.primary">
          {selectedTab === 0 ? 'CTF Version Overrides' : 'Inspection Bundle'}
        </Typography>
      </Breadcrumbs>

      <Paper
        elevation={4}
        sx={{ margin: 4, marginTop: 4, height: 800, overflowY: 'auto', overflowX: 'hidden', padding: 2 }}
      >
        <Tabs indicatorColor="secondary" value={selectedTab} onChange={handleTabs} scrollButtons={false}>
          <Tab label="CTF Version Overrides" data-testid="ctf-overrides-tab" />
          <Tab label="Inspection Bundle" data-testid="inspection-bundle-tab" />
        </Tabs>
        <GVTabPanel value={selectedTab} index={0}>
          <Grid container direction="column" sx={{ paddingLeft: 2, paddingTop: 2 }}>
            <CardHeader
              title="CTF Version Selector"
              subheader="In this section you can choose which CTF Version Verify should be pointing to."
            />
            <VersionSelector />
            {resolvedVersion && (
              <Typography sx={{ marginTop: 2 }} variant="body1">
                Currently resolved version: {resolvedVersion} <br />                
              </Typography>
            )}
          </Grid>
        </GVTabPanel>
        <GVTabPanel value={selectedTab} index={1}>
          <InspectionBundle />
        </GVTabPanel>
      </Paper>
    </>
  );
};
