/* eslint-disable array-callback-return */
import { PayloadAction } from '@reduxjs/toolkit';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { PDFManagerFactory } from 'pdftron';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  files,
  getAnnotations,
  getDifferences,
  inspection,
  getInternalAnnotationsVisibilityDefault,
  getDisplayedDifferences,
  getPageRangeSelector,
  getMultiFileNamesJoined,
  getInspectionSettings,
  getAllAnnotationsLoaded,
  getInternalAnnotationVisibility,
} from 'store';
import {
  Difference,
  DocumentStates,
  DocumentTextProcessing,
  DocumentTypes,
  InputAnnotation,
  InspectionSettings,
  PDFTronTools,
  Polygons,
  StoreState,
} from 'types';
import { isInspectionUrl, isReportsUrl, isResultUrl } from 'utils/location';
import EventHandlers from './EventHandlers';
import { actions, LoadWebViewerAction } from './reducer';
import { getFileId } from './selectors';

function handleLoadWebViewer({ payload: { instance, documentType } }: PayloadAction<LoadWebViewerAction>) {
  PDFManagerFactory.build(documentType, instance);
  // This is for retro-compatibility for everything that is still using PDFTronManager
  // In the future this will be deprecated in favor of PDFManagerFactory/Mixins approach
  PDFTronManager.setPDFInstance(documentType, instance);
  // register event handlers for a given documentType
  EventHandlers.register(documentType);

  const viewer = PDFManagerFactory.getPDFDocManager()?.getInstance(documentType);
  if (!viewer) return;
  if (viewer?.instance?.UI?.iframeWindow?.frameElement) {
    const iframeEl = viewer.instance.UI.iframeWindow.frameElement;
    iframeEl.classList.add(`${documentType}-iframe`); // for e2e tests to know which docType corresponds to the iframe
  }
}

function* handleUnloadViewers({ payload: documentType }: PayloadAction<DocumentTypes>) {
  const state: StoreState = yield select();
  const hideDisplay = state.inspection.differenceViewOptions.hideDisplayPanels;
  if (!hideDisplay.source && !hideDisplay.target) {
    const wrapper = PDFManagerFactory.getViewer(documentType);
    if (wrapper) {
      wrapper?.instance.UI.dispose();
    }
    EventHandlers.unregister(documentType);

    PDFManagerFactory.unloadViewer(documentType);
  }
}

// @pdftron-refactor A lot of the logic inside this handler has to be reviewed and refactored accordingly
function* handleLoadDocument({ payload }: PayloadAction<DocumentTypes>) {
  const documentType = payload;
  const viewer = PDFManagerFactory.getViewer(documentType);
  const state: StoreState = yield select();
  const { originalName } = state.files[documentType].file;
  const mergedFileNames: string = yield select(getMultiFileNamesJoined(documentType));
  const showAnnotations: boolean = yield select(getInternalAnnotationVisibility);
  if (viewer) {
    // Document has loaded

    // generate inspectedAnnotations after both documents have loaded
    yield PDFManagerFactory.getPDFDocManager()?.generateInspectedAnnotations(documentType);

    const totalPageCount = viewer.getTotalPages();

    const hasLiveText: boolean = yield call([viewer, viewer.loadLiveText]);
    if (hasLiveText) {
      yield put(
        inspection.actions.setLiveText({
          documentType,
          liveText: true,
        }),
      );
    }

    yield put(
      inspection.actions.setTotalPageCount({
        documentType,
        totalPageCount,
      }),
    );

    // initialize the document page range
    const { source: sourcePageRange, target: targetPageRange }: ReturnType<typeof getPageRangeSelector> = yield select(
      getPageRangeSelector,
    );
    const currentSetPageRange = documentType === DocumentTypes.source ? sourcePageRange : targetPageRange;
    // if this is the first time loading the document (not reloading a document), we need to initialize the active page range as the set of all pages
    if (currentSetPageRange.length === 0) {
      yield put(
        inspection.actions.setPageRange({
          documentType,
          pageRange: Array.from({ length: totalPageCount }, (_, i) => i + 1),
        }),
      );
    }

    const { fileId } = yield select(getFileId(documentType));
    if (window.analytics) {
      window.analytics.track('document-loaded', {
        fileName: originalName || mergedFileNames,
        fileId,
        liveText: hasLiveText,
        panel: documentType === 'target' ? 'new' : documentType,
        pageQty: totalPageCount,
      });
    }

    // Finished processing document text
    yield put(
      inspection.actions.setLiveTextProcess({
        documentType,
        documentTextProcessing: DocumentTextProcessing.COMPLETED,
      }),
    );
    yield put(
      inspection.actions.setLoadingState({
        documentType,
        documentState: DocumentStates.LOADED,
      }),
    );
    if (!showAnnotations) {
      yield put(inspection.actions.setDefaultInternalAnnotationsVisibility(true));
    }
  }
}

function* waitForAnnotationsLoaded() {
  yield takeEvery(actions.loadAnnotations.type, function* () {
    const allAnnotationsLoaded: boolean = yield select(getAllAnnotationsLoaded);
    if (allAnnotationsLoaded) {
      PDFManagerFactory.getPDFDocManager()?.drawAnnotations();
    }
  });
}

function handleInternalAnnotationsVisibility(action: PayloadAction<boolean>) {
  PDFManagerFactory.getPDFDocManager()?.hideOrShowInternalAnnotations(action.payload);
}

function* handleInternalAnnotationsVisibilityToDefault() {
  const defaultValue: boolean = yield select(getInternalAnnotationsVisibilityDefault);
  PDFManagerFactory.getPDFDocManager()?.hideOrShowInternalAnnotations(defaultValue);
}

function* handleRemoveFile({ payload }: PayloadAction<DocumentTypes>) {
  yield put(actions.unloadDocument(payload));
}

export default [
  // web viewer initialization
  takeEvery(actions.loadWebViewer.type, handleLoadWebViewer),
  // document loading
  takeEvery(actions.loadDocument.type, handleLoadDocument),
  // loading/reloading of annotations
  waitForAnnotationsLoaded(),
  // clean up for unmount
  takeEvery(actions.unloadViewers.type, handleUnloadViewers),
  // handle show/hide internal annotations
  takeEvery(inspection.actions.setInternalAnnotationsVisibility.type, handleInternalAnnotationsVisibility),
  takeEvery(inspection.actions.setDefaultInternalAnnotationsVisibility.type, handleInternalAnnotationsVisibility),
  takeEvery(
    inspection.actions.setInternalAnnotationsVisibilityToDefault.type,
    handleInternalAnnotationsVisibilityToDefault,
  ),
  // handle unloading of document when file is removed manually
  takeEvery(files.actions.removeFile.type, handleRemoveFile),
];
