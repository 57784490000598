import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileStatus, InspectFileState, MultipleFileStatus } from 'types';
import { DocumentType, DocumentTypes } from 'types/inspection';
import { initialSingleFileState, initialFilesState } from './filesState';

type FileAddPayload = {
  documentType: DocumentType;
  fileState: InspectFileState;
  isNewFile: boolean;
};

type RequestKeyPayload = {
  documentType: DocumentType;
  requestKey: string;
};

type FileRemovePayload = {
  documentType: DocumentType;
};

type MultipleFileStatusPayload = {
  documentType: DocumentType;
  status: MultipleFileStatus;
};

type OCRStatusPayload = {
  documentType: DocumentType;
  status: FileStatus | '';
};

type ShapesJobStatusPayload = FileStatus | '';

const files = createSlice({
  name: 'files',
  initialState: initialFilesState,
  reducers: {
    setFileFromLibrary: (state, action: PayloadAction<FileAddPayload>) => {
      state[action.payload.documentType].file = action.payload.fileState;
    },
    setFile: (state, action: PayloadAction<FileAddPayload>) => {
      state[action.payload.documentType].file = action.payload.fileState;
    },
    removeFile: (state, action: PayloadAction<FileRemovePayload>) => {
      state[action.payload.documentType] = initialSingleFileState;
    },
    setFilesList: (state, action: PayloadAction<Array<InspectFileState>>) => {
      state.filesList = action.payload;
    },
    resetStore: () => {
      return initialFilesState;
    },
    setIsFetchingInspectionFiles: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        // if I'm fetching new files I need to reset the old ones
        state[DocumentTypes.source] = initialSingleFileState;
        state[DocumentTypes.target] = initialSingleFileState;
      }
      state.isFetchingInspectionFiles = action.payload;
    },
    setFetchedFile: (state, action: PayloadAction<FileAddPayload>) => {
      state[action.payload.documentType].file = action.payload.fileState;
    },
    setIsAiErrorOpen: (state, action: PayloadAction<boolean>) => {
      state.isAiErrorOpen = action.payload;
    },
    setRequestKey: (state, action: PayloadAction<RequestKeyPayload>) => {
      state.requestKeys[action.payload.documentType] = action.payload.requestKey;
    },
    setMultipleFileStatus: (state, action: PayloadAction<MultipleFileStatusPayload>) => {
      state.multipleFileStatus[action.payload.documentType] = action.payload.status;
    },
    setOCRStatus: (state, action: PayloadAction<OCRStatusPayload>) => {
      state.ocrStatus[action.payload.documentType] = action.payload.status;
    },
    setOCRHasRun: (state, action: PayloadAction<boolean>) => {
      state.ocrHasRun = action.payload;
    },
    setFileCreationCancelled: (state, action: PayloadAction<boolean>) => {
      state.isCreationCancelled = action.payload;
    },
    transferSourceToTarget: (state) => {
      state[DocumentTypes.target].file = state[DocumentTypes.source].file;
      state[DocumentTypes.source] = initialSingleFileState;
    },
    setShapesJobStatus: (state, action: PayloadAction<ShapesJobStatusPayload>) => {
      state.shapesJobStatus = action.payload;
    },
    setFileStatus: (state, action: PayloadAction<{ documentType: DocumentType; status: FileStatus }>) => {
      state[action.payload.documentType].file.status = action.payload.status;
    }
  },
});

export default files;
/* eslint-enable no-param-reassign */
