import { DocumentTypes, Layer, PDFTronTools, Separation } from 'types';
import { PDFManagerFactory } from 'pdftron';
import { actions } from 'pdftron/docManager/reducer';
import store from 'store/store';
import { Core } from '@pdftron/webviewer';
import WebViewerWrapper from 'pdftron/core/WebViewerWrapper';
import {
  inspection,
  getSourceLayers,
  getTargetLayers,
  getSourceSeparations,
  getTargetSeparations,
  getSeparationHidden,
} from 'store';
import Layers from 'pdftron/core/Layers';
import Separations from 'pdftron/core/Separations';
import { convertWebviewerLayerToVerifyLayer } from 'utils/layerUtils';
import { isInspectionUrl } from 'utils/location';
import { AnnotationAction } from 'components/PDFViewer/Utils';

class EventHandlers {
  private static getViewer(documentType: DocumentTypes): WebViewerWrapper | null {
    const pdfDocManager = PDFManagerFactory.getPDFDocManager();
    if (pdfDocManager) {
      return pdfDocManager.getInstance(documentType);
    }
    return null;
  }

  private static onDocumentLoaded(documentType: DocumentTypes) {
    const viewer = this.getViewer(documentType);
    if (!viewer) return;

    viewer.instance.Core.documentViewer.addEventListener('documentLoaded', async () => {
      const docViewer = viewer.instance.Core.documentViewer;
      const doc = docViewer.getDocument();

      doc.setOverprintPreviewMode(viewer.instance.Core.OverprintPreviewMode.ON);

      doc.getLayersArray().then((docLayers) => {
        const getLayers = documentType === DocumentTypes.source ? getSourceLayers : getTargetLayers;
        const storeLayers = getLayers(store.getState());
        if (docLayers.length > 0) {
          // file has layers
          if (storeLayers) {
            const layers = new Layers(viewer.instance);
            // if layer settings are already set, we need to reload the document with only the visible layers
            layers.refreshLayerView(storeLayers);
          } else {
            // file has layers and we need to add them to store
            const convertedLayers: Layer[] = docLayers.map(convertWebviewerLayerToVerifyLayer);

            store.dispatch(inspection.actions.setLayers({ documentType, layers: convertedLayers }));
          }
        }
      });

      doc.enableColorSeparations({ checkIfBaseColorsUsed: false });

      const getSeparations = documentType === DocumentTypes.source ? getSourceSeparations : getTargetSeparations;
      const storeSeparations = getSeparations(store.getState());
      const storeSeparationsNames = storeSeparations?.map((separation) => {
        return separation.name;
      });

      const separations: any[] = [];
      const unwantedSeparations = ['Cyan', 'Magenta', 'Yellow', 'Black', 'All'];

      // timeout will be used to determine when the final colorSeparationAdded event has fired
      let timeout: ReturnType<typeof setTimeout>;

      doc.addEventListener('colorSeparationAdded', (colorData) => {
        if (timeout) {
          clearTimeout(timeout);
        }

        // if incoming colorData is not an unwanted separation and not already in the store, add it to the store
        if (!unwantedSeparations.includes(colorData.name) && !storeSeparationsNames?.includes(colorData.name)) {
          separations.push(colorData);

          const convertedSeparations: Separation[] = separations.map((separation) => ({
            name: separation.name,
            enabled: separation.enabled,
            rgb: separation.rgb,
          }));

          store.dispatch(inspection.actions.setSeparations({ documentType, separations: convertedSeparations }));
        }

        timeout = setTimeout(() => {
          const separationHidden = getSeparationHidden(store.getState());
          // if neither document has a hidden separation we disable separations for both docs
          if (!separationHidden) {
            doc.disableColorSeparations();
            docViewer.refreshAll();
            docViewer.updateView();
          } else {
            // if separations are hidden we refresh the view for the document which just finished loading
            const currentDocSeparations = getSeparations(store.getState());
            if (currentDocSeparations?.some((separation) => !separation.enabled)) {
              const separations = new Separations(viewer.instance);
              currentDocSeparations.forEach((separation) => {
                separations.refreshSeparationView(separation);
              });
            }
          }
        }, 1500);
      });

      store.dispatch(actions.loadDocument(documentType));
    });
  }

  private static onAnnotationsLoaded(documentType: DocumentTypes) {
    this.getViewer(documentType)?.instance.Core.documentViewer.addEventListener('annotationsLoaded', () => {
      store.dispatch(actions.loadAnnotations(documentType));
    });
  }

  private static onAnnotationChanged(documentType: DocumentTypes) {
    this.getViewer(documentType)?.instance.Core.annotationManager.addEventListener(
      'annotationChanged',
      (
        annotations: Core.Annotations.Annotation[],
        action: string,
        info: Core.AnnotationManager.AnnotationChangedInfoObject,
      ) => {
        // PDFTron loads a bunch of annotations at the beginning that aren't ours.
        if (!info.imported) {
          if (!isInspectionUrl()) return;

          const pdfDocManager = PDFManagerFactory.getPDFDocManager();
          if (!pdfDocManager) return;

          const annotation = annotations && annotations[0];
          if (!annotation || !pdfDocManager.isGVAnnotation(annotation)) return;

          const handlers: Partial<
            Record<
              PDFTronTools,
              (documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) => void
            >
          > = {
            [PDFTronTools.MARQUEE_ZONE]: (...p) => pdfDocManager.marqueeAnnotationChanged(...p),
            [PDFTronTools.CROSSOUT]: (...p) => pdfDocManager.crossoutAnnotationChanged(...p),
            [PDFTronTools.ZONE]: (...p) => pdfDocManager.textZoneAnnotationChanged(...p),
            [PDFTronTools.GRAPHIC]: (...p) => pdfDocManager.graphicZoneAnnotationChanged(...p),
            [PDFTronTools.CROP]: (...p) => pdfDocManager.cropZoneAnnotationChanged(...p),
          };

          const handler = handlers[annotation.ToolName as PDFTronTools];
          if (handler) {
            handler(documentType, annotation, action);
          }
        }
      },
    );
  }

  private static onAnnotationSelected(documentType: DocumentTypes) {
    this.getViewer(documentType)?.instance.Core.annotationManager.addEventListener(
      'annotationSelected',
      (annotations: Core.Annotations.Annotation[], action: any) => {
        if (action === AnnotationAction.SELECTED) {
          if (!isInspectionUrl()) return;

          const pdfDocManager = PDFManagerFactory.getPDFDocManager();
          if (!pdfDocManager) return;

          const annotation = annotations && annotations[0];
          if (!annotation || !pdfDocManager.isGVAnnotation(annotation)) return;

          const handlers: Partial<
            Record<
              PDFTronTools,
              (documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) => void
            >
          > = {
            [PDFTronTools.MARQUEE_ZONE]: (...p) => pdfDocManager.marqueeAnnotationSelected(...p),
            [PDFTronTools.CROSSOUT]: (...p) => pdfDocManager.crossoutAnnotationSelected(...p),
            [PDFTronTools.ZONE]: (...p) => pdfDocManager.textZoneAnnotationSelected(...p),
            [PDFTronTools.GRAPHIC]: (...p) => pdfDocManager.graphicZoneAnnotationSelected(...p),
            [PDFTronTools.CROP]: (...p) => pdfDocManager.cropZoneAnnotationSelected(...p),
            [PDFTronTools.POLYGON]: (...p) => pdfDocManager.polygonZoneAnnotationSelected(...p),
          };

          const handler = handlers[annotation.ToolName as PDFTronTools];
          if (handler) {
            handler(documentType, annotation, action);
          }
        }
      },
    );
  }

  public static register(documentType: DocumentTypes) {
    this.onDocumentLoaded(documentType);
    this.onAnnotationsLoaded(documentType);
    this.onAnnotationChanged(documentType);
    this.onAnnotationSelected(documentType);
  }

  public static unregister(documentType: DocumentTypes) {
    const instance = this.getViewer(documentType)?.instance;

    if (instance) {
      instance?.Core.documentViewer.dispose();
    }
  }
}

export default EventHandlers;
