import { GVToggleButton } from 'components/common';
import CropIcon from '@mui/icons-material/Crop';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { useSelector } from 'react-redux';
import { getAllAnnotationsLoaded, getFilesMetadata, getIsSingleFile, getSelectedTool } from 'store';
import { makeStyles } from 'tss-react/mui';
import { DocumentTypes, PdfBoxType, PDFTool, PDFTronTools } from 'types';
import { useState, MouseEvent } from 'react';
import { Box, Button, IconButton, ListItem, ListItemButton, Menu, MenuList, Theme } from '@mui/material';
import { PDFManagerFactory } from 'pdftron';
import BleedBoxIcon from 'components/icons/PDFBoxes/BleedBoxIcon';
import TrimBoxIcon from 'components/icons/PDFBoxes/ArtBoxIcon';
import ArtBoxIcon from 'components/icons/PDFBoxes/ArtBoxIcon';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    toggleButton: {
      padding: '0px',
      height: '48px',
      width: '48px',
    },
    toggleButtonBorderRadius: {
      borderRightWidth: '0px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    toggleIcon: { fontSize: '30px', padding: '0', color: 'white' },
    arrowButton: {
      minWidth: '25px',
      paddingRight: '0px',
      paddingLeft: '0px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      height: '48px',
    },
    arrowButtonSelected: {
      backgroundColor: '#EA3632',
      borderLeft: '1px solid black',
      '&.MuiButtonBase-root': {
        '&:hover': {
          backgroundColor: 'rgba(234, 54, 50, 0.85)',
        },
      },
    },
    arrowIcon: {
      transform: 'rotate(0deg)',
      transition: 'transform 0.3s ease',
    },
    arrowIconSelected: {
      transform: 'rotate(180deg)',
      transition: 'transform 0.3s ease',
    },
    menuList: {
      padding: '16px !important',
    },
    zoneIcon: {
      color: 'white',
    },
    listItem: {
      padding: '8px 16px',
    },
    iconButton: {
      padding: 0,
      ' .MuiBox-root': {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '17px',
        color: 'white',
      },
    },
    sliderButton: {
      zIndex: 1,
      flex: 1,
      opacity: 1,
      color: '#FFFFFF',
      fontSize: '10px',
      fontWeight: '600',
      minWidth: 'initial',
    },
  };
});

interface CropToolProps {
  handleSelectTool: (tool: PDFTool) => void;
}
const CropTool = ({ handleSelectTool }: CropToolProps) => {
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const annotationLoaded = useSelector(getAllAnnotationsLoaded);
  const selectedTool = useSelector(getSelectedTool);
  const isSingleFile = useSelector(getIsSingleFile);
  const sideMenuOpen = Boolean(anchorEl);
  const cropToolActive = selectedTool === PDFTronTools.CROP;
  const [selectedDocument, setSelectedDocument] = useState(DocumentTypes.source);
  const { masterMetadata, sampleMetadata } = useSelector(getFilesMetadata);

  const masterPdfBoxes = masterMetadata?.pdfBoxes || {};
  const samplePdfBoxes = sampleMetadata?.pdfBoxes || {};

  const checkPdfBoxes = (pdfBoxes: any) => ({
    hasArtBox: PdfBoxType.artBox in pdfBoxes,
    hasTrimBox: PdfBoxType.trimBox in pdfBoxes,
    hasBleedBox: PdfBoxType.bleedBox in pdfBoxes,
  });

  const masterBoxInfo = checkPdfBoxes(masterPdfBoxes);
  const sampleBoxInfo = checkPdfBoxes(samplePdfBoxes);

  const handleDocumentToggle = (option: React.SetStateAction<DocumentTypes>) => {
    setSelectedDocument(option);
  };

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!cropToolActive) handleSelectTool(PDFTronTools.CROP);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyCrop = (pdfBoxType: PdfBoxType) => {
    const docManager = PDFManagerFactory.getPDFDocManager();
    const pdfBoxes =
      selectedDocument === DocumentTypes.source ? masterPdfBoxes[pdfBoxType] : samplePdfBoxes[pdfBoxType];
    if (pdfBoxes) {
      docManager?.applyPDFBox(selectedDocument, pdfBoxes);
    }
    if (!cropToolActive) {
      handleSelectTool(PDFTronTools.CROP);
    }
    setAnchorEl(null);
  };

  const isBoxDisabled = (boxType: PdfBoxType) => {
    const boxInfo = selectedDocument === DocumentTypes.source ? masterBoxInfo : sampleBoxInfo;

    switch (boxType) {
      case PdfBoxType.artBox:
        return !boxInfo.hasArtBox;
      case PdfBoxType.trimBox:
        return !boxInfo.hasTrimBox;
      case PdfBoxType.bleedBox:
        return !boxInfo.hasBleedBox;
      default:
        return true; // Default to disabled if an unknown box type is passed
    }
  };

  return (
    <>
      <GVTooltip title={'Crop'} placement="bottom">
        <span>
          <GVToggleButton
            data-testid="markup_tools_crop"
            value={PDFTronTools.CROP}
            id="zoneArea"
            disabled={!annotationLoaded}
            selected={cropToolActive}
            onClick={() => {
              handleSelectTool(PDFTronTools.CROP);
            }}
            className={cx(classes.toggleButton, { [classes.toggleButtonBorderRadius]: !isSingleFile })}
          >
            <CropIcon color="primary" fontSize="inherit" className={classes.toggleIcon} />
          </GVToggleButton>
        </span>
      </GVTooltip>
      {!isSingleFile && (
        <>
          <GVTooltip title={`More crop options`} placement="top">
            <span style={{ marginLeft: 0 }}>
              <Button
                className={cx(classes.arrowButton, {
                  [classes.arrowButtonSelected]: sideMenuOpen || cropToolActive,
                })}
                id={`crop-more-options`}
                color="primary"
                size="small"
                onClick={handleOpenMenu}
                variant="outlined"
                disabled={!annotationLoaded}
              >
                <ArrowDropDownIcon
                  className={cx({
                    [classes.arrowIconSelected]: sideMenuOpen || cropToolActive,
                    [classes.arrowIcon]: !sideMenuOpen,
                  })}
                />
              </Button>
            </span>
          </GVTooltip>
          <Menu
            anchorEl={anchorEl}
            open={sideMenuOpen}
            onClose={handleClose}
            className={classes.menuList}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuList dense sx={{ paddingBottom: '0 !important' }}>
              <ListItem className={classes.listItem} sx={{ paddingTop: '0 !important' }}>
                <Box display="flex" alignItems="center">
                  <Box sx={{ color: 'rgba(255, 255, 255, 0.38)', fontSize: '12px', fontWeight: '400' }} mr={1}>
                    CROP ON
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      borderRadius: '3.75px',
                      overflow: 'hidden',
                      backgroundColor: '#101113',
                      width: '100px',
                      height: '20px',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 1,
                        left: selectedDocument === DocumentTypes.source ? 0 : '50%',
                        width: '50%',
                        height: '17.5px',
                        backgroundColor: '#EA3632',
                        transition: 'left 0.3s ease',
                        padding: '2.5px 5px',
                        borderRadius: '3.75px',
                      }}
                    />
                    <Button className={classes.sliderButton} onClick={() => handleDocumentToggle(DocumentTypes.source)}>
                      Source
                    </Button>

                    <Button className={classes.sliderButton} onClick={() => handleDocumentToggle(DocumentTypes.target)}>
                      New
                    </Button>
                  </Box>
                </Box>
              </ListItem>
              <ListItemButton
                className={classes.listItem}
                disabled={isBoxDisabled(PdfBoxType.bleedBox)}
                onClick={() => applyCrop(PdfBoxType.bleedBox)}
              >
                <IconButton className={classes.iconButton}>
                  <Box display="flex" alignItems="center">
                    <BleedBoxIcon />
                    <Box ml={'10px'}>BleedBox</Box>
                  </Box>
                </IconButton>
              </ListItemButton>

              <ListItemButton
                className={classes.listItem}
                disabled={isBoxDisabled(PdfBoxType.trimBox)}
                onClick={() => applyCrop(PdfBoxType.trimBox)}
              >
                <IconButton className={classes.iconButton}>
                  <Box display="flex" alignItems="center">
                    <TrimBoxIcon />
                    <Box ml={'10px'}>TrimBox</Box>
                  </Box>
                </IconButton>
              </ListItemButton>

              <ListItemButton
                className={classes.listItem}
                disabled={isBoxDisabled(PdfBoxType.artBox)}
                onClick={() => applyCrop(PdfBoxType.artBox)}
              >
                <IconButton className={classes.iconButton}>
                  <Box display="flex" alignItems="center">
                    <ArtBoxIcon />
                    <Box ml={'10px'}>ArtBox</Box>
                  </Box>
                </IconButton>
              </ListItemButton>
            </MenuList>
          </Menu>
        </>
      )}
    </>
  );
};

export default CropTool;
