/* eslint-disable @typescript-eslint/no-empty-interface */
import { applyMixins, PDFManagerFactory, WebViewerWrapper } from 'pdftron';
import { DocumentTypes, InputAnnotation, InspectionSettings, PDFTronTools, Polygons } from 'types';
import Annotation from './Annotation';
import Crossout from './AnnotationTools/Crossout';
import GraphicZone from './AnnotationTools/GraphicZone';
import MarqueeZone from './AnnotationTools/MarqueeZone';
import TextZone from './AnnotationTools/TextZone';
import Difference from './Difference';
import InspectedAnnotation from './InspectedAnnotation';
import Tools from './Tools';
import BarcodeOverlay from './AnnotationTools/BarcodeOverlay';
import CropZone from './AnnotationTools/CropZone';
import PolygonZone from './AnnotationTools/PolygonZone';
import store from 'store/store';
import { getAnnotations, getDifferences, getDisplayedDifferences, getInspectionSettings } from 'store';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import { isInspectionUrl, isReportsUrl, isResultUrl } from 'utils/location';

class PDFDocManager {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  setInstance(instance: WebViewerWrapper | null, documentType: DocumentTypes) {
    if (documentType === DocumentTypes.source) {
      this.source = instance;
    } else if (documentType === DocumentTypes.target) {
      this.target = instance;
    }
  }

  getInstance(documentType: DocumentTypes): WebViewerWrapper | null {
    if (documentType === DocumentTypes.source) {
      return this.source;
    }
    if (documentType === DocumentTypes.target) {
      return this.target;
    }
    return null;
  }

  getOtherInstance(instance: WebViewerWrapper | null) {
    if (instance && instance.documentType === DocumentTypes.source) {
      return this.getInstance(DocumentTypes.target);
    }
    return this.getInstance(DocumentTypes.source);
  }

  drawAnnotations() {
    const state = store.getState();
    const allDifferences = getDifferences(state);
    const displayedDifferences = getDisplayedDifferences(state);

    const polygons: Polygons = state.inspection.polygons;
    if (polygons) {
      this.drawPolygons(DocumentTypes.source, polygons.source?.points);
      this.drawPolygons(DocumentTypes.target, polygons.target?.points);
    }

    const annotationsList: { source: InputAnnotation[]; target: InputAnnotation[] } = getAnnotations(state);
    const inspectionSettings: InspectionSettings = getInspectionSettings(state);

    const viewer = this.getInstance(DocumentTypes.source);
    const targetViewer = this.getInstance(DocumentTypes.target);

    if (
      (!inspectionSettings.singleFile && (!viewer || !targetViewer)) ||
      (inspectionSettings.singleFile && !targetViewer)
    )
      return;
    if (viewer) {
      if (viewer.instance?.UI?.iframeWindow?.frameElement) {
        const iframeEl = viewer.instance.UI.iframeWindow.frameElement;
        iframeEl.classList.add(`${DocumentTypes.source}-loaded`); // for e2e tests to know the annotations are loaded
      }
      const annotationManager = viewer.docViewer.getAnnotationManager();
      PDFAnnotationManager.setInstance(DocumentTypes.source, annotationManager);
      // getting a 1-totalPages sequence
      const pages = Array.from({ length: viewer?.getTotalPages() }, (_, index) => index + 1);

      // repainting pages to show all annotations properly
      viewer.docViewer.recalculateLayout(pages);
    }

    if (targetViewer) {
      if (targetViewer.instance?.UI?.iframeWindow?.frameElement) {
        const iframeEl = targetViewer.instance.UI.iframeWindow.frameElement;
        iframeEl.classList.add(`${DocumentTypes.target}-loaded`); // for e2e tests to know the annotations are loaded
      }
      const targetAnnotationManager = targetViewer?.docViewer.getAnnotationManager();
      if (targetAnnotationManager) {
        PDFAnnotationManager.setInstance(DocumentTypes.target, targetAnnotationManager);
      }

      // getting a 1-totalPages sequence
      const targetPages = Array.from({ length: targetViewer.getTotalPages() }, (_, index) => index + 1);

      // repainting pages to show all annotations properly
      targetViewer.docViewer.recalculateLayout(targetPages);
    }

    // paint annotations here
    // at this point we are ready to draw annotations on the document
    // @pdftron-refactor - all this drawing here has to be reviewed - we may be doing a lot of unnecesary stuff
    if (isInspectionUrl()) {
      if (isResultUrl() || isReportsUrl()) {
        PDFAnnotationManager.deleteAllInputAnnotations(); // remove markups (zones/underlines,etc) so they dont appear in results
        // displayedDifferences is used here because we dont need to account for discarded differences
        PDFAnnotationManager.changeDocumentsAnnotationVisibility(displayedDifferences); // toggle visibility of differences
        PDFManagerFactory.getPDFDocManager()?.changeInspectedAnnotationVisibility(true);
      } else {
        // allDifferences is used here because at this point we have to hide everything to let zones and crossout to be the only ones visible
        PDFAnnotationManager.changeDocumentsAnnotationVisibility(allDifferences, false, false); // toggle visibility of differences
        PDFManagerFactory.getPDFDocManager()?.changeInspectedAnnotationVisibility(false);

        const sourceCrossouts = annotationsList[DocumentTypes.source].filter(
          (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.CROSSOUT,
        );
        const targetCrossouts = annotationsList[DocumentTypes.target].filter(
          (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.CROSSOUT,
        );
        PDFManagerFactory.getPDFDocManager()?.reloadCrossouts(sourceCrossouts, targetCrossouts);

        const sourceCropZones = annotationsList[DocumentTypes.source].filter(
          (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.CROP,
        );
        const targetCropZones = annotationsList[DocumentTypes.target].filter(
          (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.CROP,
        );
        PDFManagerFactory.getPDFDocManager()?.reloadCropZones(sourceCropZones, targetCropZones);

        PDFAnnotationManager.drawZones();

        const sourceMarqueeZoneAnnotationList = annotationsList[DocumentTypes.source].filter(
          (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.MARQUEE_ZONE,
        );
        const targetMarqueeZoneAnnotationList = annotationsList[DocumentTypes.target].filter(
          (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.MARQUEE_ZONE,
        );
        PDFManagerFactory?.getPDFDocManager()?.reloadMarqueeZones(
          sourceMarqueeZoneAnnotationList,
          targetMarqueeZoneAnnotationList,
        );
      }
    }
  }
}

interface PDFDocManager
  extends TextZone,
    MarqueeZone,
    Difference,
    Annotation,
    GraphicZone,
    Tools,
    Crossout,
    CropZone,
    InspectedAnnotation,
    BarcodeOverlay,
    PolygonZone {}

applyMixins(PDFDocManager, [
  TextZone,
  MarqueeZone,
  Difference,
  Annotation,
  GraphicZone,
  Tools,
  Crossout,
  CropZone,
  InspectedAnnotation,
  BarcodeOverlay,
  PolygonZone,
]);

export default PDFDocManager;
