import { useEffect } from 'react';
import { Grid, Theme } from '@mui/material';
import { GVBackButton } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentStates, DocumentTypes } from 'types';
import { fetchFileList, preprocessFile } from 'store/requests';
import { getStyleVariables } from 'styles/vars';
import FileList from './FileList';
import { makeStyles } from 'tss-react/mui';
import UploadProgressBackground from './UploadProgressBackgound';
import { getConvertingFiles, getFileRequestKeys } from 'store/files/filesSelectors';
import { abortRequests, stopPolling } from '@redux-requests/core';
import { pollFilesStatus, pollFileStatus } from 'store/request/files/actions';
import inspection from 'store/inspection/inspection';
import { files } from 'store';

interface Library {
  document: DocumentTypes;
  showLibrary: boolean;
  title: string;
  handleHideLibrary: (value?: boolean) => void;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    titleContainer: {
      paddingTop: '8vh',
      alignItems: 'center',
    },
    padder: {
      flex: 1,
      justifyContent: 'flex-start',
      textAlign: 'left',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    listWrapper: {
      height: '100%',
      overflowY: 'auto',
    },
    scrollBar: styleVariables.scrollBar,
  };
});

const Library = (props: Library) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const convertingFiles = useSelector(getConvertingFiles);
  const fileRequestKey = useSelector(getFileRequestKeys)[props.document];
  const pollFileStatusQuery = pollFileStatus.toString() + fileRequestKey;
  // Fetch file list when we show library
  useEffect(() => {
    if (props.showLibrary) {
      dispatch(fetchFileList());
    }
  }, [dispatch, props.showLibrary]);

  console.log(convertingFiles)
  if (convertingFiles[props.document]) {
    return (
      <UploadProgressBackground        
        preProcessingFiles
        document={props.document}
        uploadProgress={100}
        handleCancelUpload={() => {
          dispatch(stopPolling([pollFilesStatus.toString()]));
          dispatch(stopPolling([pollFileStatusQuery]));
          dispatch(
            inspection.actions.setLoadingState({ documentType: props.document, documentState: DocumentStates.INITIAL }),
          );
          dispatch(abortRequests([preprocessFile.toString()]));
          dispatch(files.actions.removeFile({ documentType: props.document }));
        }}        
        uploadCount={1}
      />
    );
  }
  return (
    <>
      <Grid container direction="row" className={classes.titleContainer}>
        <div className={classes.padder}>
          <GVBackButton action={props.handleHideLibrary} />
        </div>
        {props.title}
        <div className={classes.padder} />
      </Grid>
      <div className={`${classes.listWrapper} ${classes.scrollBar}`}>
        <FileList document={props.document} />
      </div>
    </>
  );
};

export default Library;
