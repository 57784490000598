import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TrimBoxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="crop margins">
          <rect
            id="dotted line"
            x="4.25"
            y="4.75"
            width="9.5"
            height="9.5"
            rx="0.75"
            stroke="white"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeDasharray="2 2"
          />
          <rect
            id="dotted line_2"
            x="2.25"
            y="2.75"
            width="13.5"
            height="13.5"
            rx="0.75"
            stroke="white"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeDasharray="2 2"
          />
          <rect
            id="dotted line_3"
            x="0.25"
            y="0.75"
            width="17.5"
            height="17.5"
            rx="0.75"
            stroke="white"
            strokeOpacity="0.5"
            strokeWidth="0.5"
            strokeDasharray="2 2"
          />
          <rect
            id="crop"
            x="6.25"
            y="6.75"
            width="5.5"
            height="5.5"
            rx="0.75"
            fill="#6DDAE2"
            fillOpacity="0.24"
            stroke="#6DDAE2"
            strokeWidth="0.5"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default TrimBoxIcon;
