import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Theme,
  Input,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import debounce from 'lodash.debounce';
import { fetchOrganization } from 'store/myAccount/requests';
import { useDispatchRequest } from '@redux-requests/react';
import { Organization } from 'types';
import {
  CustomProfile,
  useCustomProfiles,
  useDeleteCustomProfile,
  useUpdateCustomProfile,
  useUploadCustomProfile,
} from 'store/queries/organizations/useCustomProfiles';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';
import { GVTypography } from 'components/lib';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    tableContainer: {
      marginTop: '15px',
      maxHeight: '450px',
      overflowY: 'auto',
      width: '100%',
      maxWidth: '750px',
    },
    table: {
      width: '100%',
    },
    headerCell: {
      fontWeight: 'bold',
    },
    actionsCell: {
      textAlign: 'right',
    },
    uploadFileButton: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1, 2),
    },
    scrollBar: {
      scrollbarGutter: 'stable',
      '&::-webkit-scrollbar': {
        width: '10px',
        height: '10px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#393B3F',
        boxShadow: '0 0 1px 1px #474747',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: 'rgba(100, 102, 105, 0.6)',
        boxShadow: '0 0 1px 1px #393B3F',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#4e4e4e',
      },
    },
    deleteDialog: {
      margin: theme.spacing(2),
    },
    deleteDialogTitle: {
      textAlign: 'left',
      color: 'white',
    },
    deleteDialogContent: {
      padding: theme.spacing(3),
      textAlign: 'center',
    },
  };
});

const CustomProfiles = () => {
  const [organizationId, setOrganizationId] = useState<string>('');
  const [profileName, setProfileName] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatchRequest = useDispatchRequest();
  const [organizationInfo, setOrganizationInfo] = useState<Partial<Organization>>();
  const uploadCustomProfileMutation = useUploadCustomProfile();
  const deleteCustomProfileMutation = useDeleteCustomProfile();
  const updateCustomProfileMutation = useUpdateCustomProfile();
  const { data, isLoading } = useCustomProfiles(organizationId);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isOrgIdValid, setIsOrgIdValid] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { classes } = useStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [profileToDelete, setProfileToDelete] = useState<string | null>(null);
  const [openNewProfileModal, setOpenNewProfileModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [profileToEdit, setProfileToEdit] = useState<CustomProfile | null>(null);

  const debouncedFetchOrganization = debounce((orgId: string) => {
    if (orgId) {
      fetchOrganizationData(orgId);
    }
  }, 300);

  const fetchOrganizationData = async (orgId: string) => {
    setIsFetching(true);
    try {
      const { data: orgData } = await dispatchRequest(fetchOrganization(orgId));
      if (orgData && organizationId.startsWith('org_')) {
        setOrganizationInfo(orgData);
        setIsOrgIdValid(true);
      } else {
        setOrganizationInfo(undefined);
        setIsOrgIdValid(false);
      }
    } catch (error) {
      console.error('Error fetching organization:', error);
      setOrganizationInfo(undefined);
      setIsOrgIdValid(false);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (organizationId && organizationId.startsWith('org_')) {
      debouncedFetchOrganization(organizationId);
    } else {
      setOrganizationInfo(undefined);
      setIsOrgIdValid(false);
    }
    return () => {
      debouncedFetchOrganization.cancel();
    };
  }, [organizationId]);

  const readFileAsString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.name.endsWith('.ini')) {
      setSelectedFile(file);
    } else {
      alert('Please upload a valid .ini file');
      setSelectedFile(null);
    }
  };

  const handleOpenNewProfileModal = () => {
    setOpenNewProfileModal(true);
  };
  const handleCloseNewProfileModal = () => {
    setOpenNewProfileModal(false);
    setProfileName('');
    setSelectedFile(null);
    setProfileToEdit(null);
    setIsEditing(false);
  };

  const handleConfirmUpload = async () => {
    if (selectedFile) {
      const profileString = await readFileAsString(selectedFile);

      uploadCustomProfileMutation.mutate(
        {
          customProfileString: profileString,
          customProfileName: profileName,
          orgId: organizationId,
          fileName: selectedFile.name,
        },
        {
          onSuccess: () => {
            console.log('Profile uploaded successfully');
            setProfileName('');
            handleCloseNewProfileModal();
            setSelectedFile(null);
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          },
          onError: (error) => {
            console.error('Error uploading profile:', error);
          },
        },
      );
    }
  };

  const handleDeleteClick = (profileToDelete: string) => {
    setProfileToDelete(profileToDelete);
    setOpenDeleteModal(true);
  };
  const handleConfirmDelete = () => {
    if (profileToDelete) {
      console.log({ profileToDelete });
      deleteCustomProfileMutation.mutate(profileToDelete, {
        onSuccess: () => {
          console.log(`Profile "${profileToDelete}" deleted successfully.`);
          setOpenDeleteModal(false);
          setProfileToDelete(null);
        },
        onError: (error) => {
          console.error('Error deleting profile:', error);
          setProfileToDelete(null);
        },
      });
    }
  };

  const handleEditClick = (profile: CustomProfile) => {
    setProfileName(profile.customProfileName);
    setProfileToEdit(profile);
    setIsEditing(true);
    setOpenNewProfileModal(true);
  };

  const handleFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpdateProfile = async () => {
    if (profileToEdit) {
      const profileString = selectedFile ? await readFileAsString(selectedFile) : undefined;
      updateCustomProfileMutation.mutate(
        {
          customProfileString: profileString,
          customProfileName: profileName,
          id: profileToEdit.id,
          orgId: organizationId,
          fileName: selectedFile ? selectedFile.name : profileToEdit.fileName,
        },
        {
          onSuccess: () => {
            console.log('Profile updated successfully');
            handleCloseNewProfileModal();
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          },
          onError: (error) => {
            console.error('Error updating profile:', error);
          },
        },
      );
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginLeft: '10px' }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Typography variant="h5" data-testid="organization-title" >Organization</Typography>
          <TextField
            label="Organization ID"
            variant="outlined"
            style={{ width: '200px', display: 'flex', marginBottom: '20px' }}
            fullWidth
            margin="normal"
            value={organizationId}
            data-testid="organization-id-input"
            onChange={(e) => setOrganizationId(e.target.value)}
            helperText={(!isOrgIdValid || !organizationInfo) && organizationId !== '' ? 'Invalid Organization ID' : ''}
          />

          {organizationId && (
            <div style={{ width: '100%' }}>
              {!isLoading && organizationInfo && (
                <>
                  <Typography variant="h5">Custom Profiles</Typography>
                  {data && Array.isArray(data) && (
                    <TableContainer component={Paper} className={`${classes.tableContainer} ${classes.scrollBar}`}>
                      <Table size="small" stickyHeader className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.headerCell}>Profile ID</TableCell>
                            <TableCell className={classes.headerCell}>Profile Name</TableCell>
                            <TableCell className={classes.actionsCell}>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody data-testid="custom-profile-table">
                          {data.map((profile: CustomProfile) => (
                            <TableRow key={profile.id} hover>
                              <TableCell>{profile.id}</TableCell>
                              <TableCell data-testid={`profile-name-${profile.customProfileName}`}>{profile.customProfileName}</TableCell>
                              <TableCell className={classes.actionsCell}>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  data-testid={`profile-edit-${profile.customProfileName}`}
                                  onClick={() => handleEditClick(profile)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  color="secondary"
                                  size="small"
                                  data-testid={`profile-delete-${profile.customProfileName}`}
                                  onClick={() => handleDeleteClick(profile.id)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </>
              )}
              <Button
                style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}
                variant="contained"
                color="secondary"
                disabled={!organizationId || !isOrgIdValid || !organizationInfo || isFetching}
                onClick={handleOpenNewProfileModal}
              >
                Add Custom Profile
              </Button>
            </div>
          )}
        </form>
      </div>

      <Dialog open={openNewProfileModal} onClose={handleCloseNewProfileModal} maxWidth="sm" fullWidth>
        <DialogTitle >{isEditing ? 'Edit Profile' : 'Create New Profile'}</DialogTitle>
        <DialogContent style={{ padding: '20px', height: '440px', overflowY: 'auto', marginTop: '-30px'}} className={classes.scrollBar}>
          <TextField
            label="Profile Name"
            data-testid="profile-name-input"
            variant="outlined"
            fullWidth
            margin="normal"
            value={profileName}
            onChange={(e) => setProfileName(e.target.value)}
          />
          <Typography variant="subtitle1" marginTop="20px">
            Upload a GVD custom profile file (.ini) for a specific organization
          </Typography>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFileClick}
              className={classes.uploadFileButton}
            >
              Upload File
            </Button>
            <Input
              type="file"
              inputRef={fileInputRef}
              id="profile-input"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              inputProps={{ accept: '.ini' }}
              fullWidth
            />
            <GVTypography data-testid="profile-file-name">
              {selectedFile ? selectedFile.name : profileToEdit?.fileName || ''}
            </GVTypography>
          </div>
          {profileName && (selectedFile || profileToEdit?.fileName) && (
            <div style={{ marginTop: '16px' }}>
              <DialogContentText>
                {isEditing ? 'You are updating' : 'You are uploading'} the profile '{profileName}'{' '}
                {isEditing ? 'from' : 'to'} this organization:
              </DialogContentText>
              <List>
                <ListItem>
                  <ListItemText primary="Name:" secondary={organizationInfo?.name} data-testid="org-name" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Display Name:" secondary={organizationInfo?.displayName} data-testid="org-display" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Organization Name:" secondary={organizationInfo?.id} data-testid="org-id" />
                </ListItem>
              </List>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewProfileModal} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={
              (!selectedFile && !profileToEdit?.fileName) ||
              !organizationId ||
              !profileName ||
              !isOrgIdValid ||
              !organizationInfo ||
              isFetching
            }
            onClick={() => (isEditing && profileToEdit ? handleUpdateProfile() : handleConfirmUpload())}
          >
            {isEditing ? 'Update' : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        classes={{ paper: classes.deleteDialog }}
      >
        <DialogTitle className={classes.deleteDialogTitle}>Confirm Deletion</DialogTitle>
        <DialogContent className={classes.deleteDialogContent}>
          <DialogContentText>Are you sure you want to delete the custom profile '{profileToDelete}'?</DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button onClick={() => setOpenDeleteModal(false)} variant="contained" color="primary" >
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} variant="contained" color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomProfiles;
