import { DocumentToast } from 'components/DocumentToast/DocumentToast';
import { DocumentTypes } from 'types';
import { create } from 'zustand';

interface DocumentToastStateStore {
  documentToast: { [key in DocumentTypes]: DocumentToast | null };
  setDocumentToast: (documentType: DocumentTypes, toast: DocumentToast) => void;
  expireDocumentToast: (documentType: DocumentTypes, toast: DocumentToast) => void;
  expireAllToast: () => void;
}

export const useDocumentToastStateStore = create<DocumentToastStateStore>((set, get) => ({
  documentToast: { source: null, target: null },

  setDocumentToast: (documentType: DocumentTypes, toast: DocumentToast) =>
    set((state) => ({
      documentToast: {
        ...state.documentToast,
        [documentType]: toast,
      },
    })),

  expireDocumentToast: (documentType: DocumentTypes, toast: DocumentToast) => {
    const { documentToast } = get();

    const currentToast = documentToast[documentType];
    const toastToExpire = toast;

    if (currentToast?.message === toastToExpire?.message && currentToast.expired !== true) {
      set((state) => ({
        documentToast: { ...state.documentToast, [documentType]: { ...toast, expired: true } },
      }));
    }
  },

  expireAllToast: () => {
    const { documentToast } = get();

    const targetToast = documentToast[DocumentTypes.target];
    const sourceToast = documentToast[DocumentTypes.source];

    if (targetToast) {
      set((state) => ({
        documentToast: {
          ...state.documentToast,
          [DocumentTypes.target]: { ...targetToast, expired: true },
        },
      }));
    }

    if (sourceToast) {
      set((state) => ({
        documentToast: {
          ...state.documentToast,
          [DocumentTypes.source]: { ...sourceToast, expired: true },
        },
      }));
    }
  },
}));
