import {
  AllDocumentTypes,
  DocumentTypes,
  PageWidthFit,
  ReportDocumentTypes,
  ZoomLockLevel,
  ZoomOptionState,
} from 'types';
import { create } from 'zustand';

interface ZoomStateStore {
  zoomChanging: boolean;
  setZoomChanging: () => void;

  zoomOption: ZoomOptionState;
  setZoomOption: (documentType: AllDocumentTypes, value: '' | PageWidthFit) => void;

  zoomLockLevel: ZoomLockLevel;
  setZoomLockLevel: (documentType: AllDocumentTypes, value: null | number | PageWidthFit) => void;
}

export const useZoomStateStore = create<ZoomStateStore>((set, get) => ({
  zoomChanging: false,
  setZoomChanging: () =>
    set((state) => ({
      zoomChanging: !state.zoomChanging,
    })),

  zoomOption: {
    [ReportDocumentTypes.report]: PageWidthFit.fitPage,
    [DocumentTypes.source]: PageWidthFit.fitPage,
    [DocumentTypes.target]: PageWidthFit.fitPage,
  },
  setZoomOption: (documentType: AllDocumentTypes, value: '' | PageWidthFit) =>
    set((state) => ({
      zoomOption: { ...state.zoomOption, [documentType]: value },
    })),

  zoomLockLevel: {
    [DocumentTypes.source]: null,
    [DocumentTypes.target]: null,
    [ReportDocumentTypes.report]: null,
  },
  setZoomLockLevel: (documentType: AllDocumentTypes, value: null | number | PageWidthFit) =>
    set((state) => ({
      zoomLockLevel: { ...state.zoomLockLevel, [documentType]: value },
    })),
}));
