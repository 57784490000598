import { AppState } from 'types';

const initialAppState: AppState = {
  loginAttempts: [],
  snackMessage: { open: false, message: '' },
  snackMessageQueue: [],
  fetchingDifferences: false,
  autosaving: false,
  updatingInspection: false,
  startingInspection: false,
  resetIdleTimer: false,
  popUpNotifications: [],
  userPreferences: {},
  toastStatuses: {
    hasPanToolToastShown: false,
  },
  integrations: [],
  displayName: '',
  showLiveText: false,
  focusedMatchIndex: -1,
  showDifferenceBoxes: true,
  showOverlay: true,
};

export default initialAppState;
