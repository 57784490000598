import { useLocation } from 'react-router-dom';
import SettingsSection from './settings';
import MyTeam from './myTeam';
import AuditTrail from './auditTrail';
import TenantManagement from './tenantManagement';
import CustomDictionaries from './customDictionaries';
import InternalAPI from './internalAPI';
import CustomProfiles from './customProfiles';

const sections: { [key: string]: React.FC } = {
  settings: SettingsSection,
  myteam: MyTeam,
  audittrail: AuditTrail,
  tenantmanagement: TenantManagement,
  customdictionaries: CustomDictionaries,
  internalapi: InternalAPI,
  customprofiles: CustomProfiles,
};

const Body = () => {
  const location = useLocation();
  const pathname = location.pathname.split('/');
  if (Object.keys(sections).includes(pathname[2])) {
    const SectionComponent = sections[pathname[2]];
    return <SectionComponent />;
  }
  return null;
};

export default Body;
