/* eslint-disable max-classes-per-file */
import store from 'store/store';
import {
  DocumentTypes,
  DocumentType,
  Difference,
  DifferenceTypeNames,
  ZoneCustomData,
  AnnotationCustomData,
  GraphicZoneCustomData,
  MarqueeZoneCustomData,
  SubDifferenceCustomData,
  PDFTronTools,
  InputAnnotation,
  Quad,
  CropZoneCustomData,
  PolygonZoneCustomData,
} from 'types';
import {
  inspection,
  getDifferenceFilters,
  getTotalPageCount,
  getGroupByDifference,
  getGroupedDifferences,
  getFocusedDifference,
} from 'store';
import Utils from 'components/PDFViewer/Utils';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';
import DifferenceClass from 'pdftron/docManager/Difference';
import quadsToDimensions from 'pdftron/docManager/utils/quadsToDimensions';

export default class PDFAnnotationManager {
  private static AnnotationManagerInstances: { [key in DocumentTypes]: Core.AnnotationManager | null } | undefined;

  // keeps track of the selected annotation id
  static selectedId: string;

  /**
   * Sets the instances of AnnotationManager
   * @param type The related document
   * @param annotationManager The instance of annotation manager
   */
  static setInstance(type: DocumentType, annotationManager: Core.AnnotationManager): void {
    if (!PDFAnnotationManager.AnnotationManagerInstances) {
      PDFAnnotationManager.AnnotationManagerInstances = {
        source: null,
        target: null,
      };
    }
    PDFAnnotationManager.AnnotationManagerInstances[type] = annotationManager;
  }

  /**
   * Gets the AnnotationManager instance for a document
   * @param type The document which AnnotationManager is attached to
   */
  static getInstance(type: DocumentType): Core.AnnotationManager | null {
    if (!PDFAnnotationManager.AnnotationManagerInstances) return null;
    if (!PDFAnnotationManager.AnnotationManagerInstances[type]) return null;
    return PDFAnnotationManager.AnnotationManagerInstances[type];
  }

  /**
   * Gets the current annotations from a document.
   * Note that these are not the raw annotations from PDFTron.
   * @param type The related document
   */
  static getInputAnnotations(type: DocumentType): InputAnnotation[] | null {
    const annotationManager = PDFAnnotationManager.getInstance(type);
    if (!annotationManager) {
      return null;
    }
    // Filter internal annotations of PDFTron
    const filteredAnnotations = annotationManager
      .getAnnotationsList()
      .filter(
        (annotation) =>
          annotation.getCustomData(AnnotationCustomData.strikeoutAnnotation) === 'true' ||
          annotation.getCustomData(ZoneCustomData.zoneAnnotation) === 'true' ||
          annotation.getCustomData(GraphicZoneCustomData.graphicAnnotation) === 'true' ||
          annotation.getCustomData(MarqueeZoneCustomData.marqueeZoneAnotation) === 'true' ||
          annotation.getCustomData(CropZoneCustomData.cropZoneAnnotation) === 'true' ||
          annotation.getCustomData(PolygonZoneCustomData.polygonAnnotation) === 'true',
      );
    return PDFManagerFactory.getPDFDocManager()?.convertToInputAnnotation(filteredAnnotations) || null;
  }

  /**
   * Delete all input annotations by document type
   * @param documentType The related document
   */
  static deleteInputAnnotationsByDocument(documentType: DocumentType): void {
    const inputAnnotations = PDFAnnotationManager.getInputAnnotations(documentType);
    const annotationManager = PDFAnnotationManager.getInstance(documentType);
    if (!inputAnnotations || !annotationManager) {
      return;
    }

    // We need to get the actual annotation from PDFTron as our annotation type is different
    const annotationsToDelete: Core.Annotations.Annotation[] = inputAnnotations
      .filter((inputAnnot) => {
        const annotation = annotationManager.getAnnotationById(inputAnnot.annotationId);
        return annotation;
      })
      .map((inputAnnotation) => annotationManager.getAnnotationById(inputAnnotation.annotationId));

    // delete annotations list
    annotationManager.deleteAnnotations(annotationsToDelete, {
      imported: true,
      force: true,
    });
  }

  /**
   * Delete annotations by id
   * @param documentType The related document
   * @param id The id of annotation
   */
  static deleteAnnotationById(documentType: DocumentType, id: string): void {
    const inputAnnotations = PDFAnnotationManager.getInputAnnotations(documentType);
    const annotationManager = PDFAnnotationManager.getInstance(documentType);
    if (!inputAnnotations || !annotationManager) {
      return;
    }
    const inputAnnotationsToDelete = inputAnnotations.filter(
      (inputAnnot) =>
        inputAnnot.annotationId.split('_')[0] === id && annotationManager.getAnnotationById(inputAnnot.annotationId),
    ); // split function is for the highlight annotations on target file
    const annotationsToDelete: Core.Annotations.Annotation[] = inputAnnotationsToDelete.map((inputAnnotation) =>
      annotationManager.getAnnotationById(inputAnnotation.annotationId),
    );

    annotationManager.deleteAnnotations(annotationsToDelete, {
      imported: true,
      force: true,
    });
  }

  /**
   * Delete highlight annotations by document type
   * @param documentType The related document
   * @param id The id of annotation
   */
  static deleteHighlightAnnotations(documentType: DocumentType, id: string): void {
    const inputAnnotations = PDFAnnotationManager.getInputAnnotations(documentType);
    const annotationManager = PDFAnnotationManager.getInstance(documentType);
    if (!inputAnnotations || !annotationManager) {
      return;
    }
    const highlightAnnotations = inputAnnotations
      .filter((inputAnnot) => inputAnnot.annotationId === id)
      .map((inputAnnot) => annotationManager.getAnnotationById(inputAnnot.annotationId));

    annotationManager.deleteAnnotations(highlightAnnotations, {
      imported: true,
      force: true,
    });
  }

  /**
   * Delete all input annotations in both documents
   */
  static deleteAllInputAnnotations(): void {
    PDFAnnotationManager.deleteInputAnnotationsByDocument(DocumentTypes.source);
    PDFAnnotationManager.deleteInputAnnotationsByDocument(DocumentTypes.target);
  }

  static highlightDocumentDifference = (documentType: DocumentType, diffId: string) => {
    const annotManager = PDFAnnotationManager.getInstance(documentType);
    if (!annotManager) return;

    const textAnnot = annotManager.getAnnotationById(diffId);
    if (textAnnot) {
      Utils.styleSelectedRectangleAnnotation(textAnnot as Core.Annotations.RectangleAnnotation);
      annotManager.redrawAnnotation(textAnnot);
      annotManager.jumpToAnnotation(textAnnot);
    }
  };

  static highlightDifference(differenceId: string): void {
    this.highlightDocumentDifference(DocumentTypes.source, differenceId);
    this.highlightDocumentDifference(DocumentTypes.target, differenceId);
  }

  /**
   * This function draws zones on both source and target documents, so it needs
   * both of them to be available and loaded
   *
   * @param state Store state
   * @param dispatch Redux dispatch function
   * @pdftron-refactor This is now being used in the saga - it can be refactored, split in parts, put into mixins etc.
   */
  public static drawZones(): void {
    const { dispatch } = store;
    const sourceInstance = PDFTronManager.getPDFInstance(DocumentTypes.source);
    const targetInstance = PDFTronManager.getPDFInstance(DocumentTypes.target);
    if (!sourceInstance || !targetInstance) return;
    const sourceAnnotManager = sourceInstance.Core.documentViewer.getAnnotationManager();
    const targetAnnotManager = targetInstance.Core.documentViewer.getAnnotationManager();
    if (!sourceAnnotManager || !targetAnnotManager) return;

    // Get both documents
    const sourceDocument = sourceInstance.Core.documentViewer.getDocument();
    const targetDocument = targetInstance.Core.documentViewer.getDocument();

    // Helper for creating zone annotations and searching their associated text
    const createAnnotationPromise = async (
      zoneId: number,
      annotationMarkup: InputAnnotation,
      annotationManager: Core.AnnotationManager,
      instance: WebViewerInstance,
      document: Core.Document,
      editable: boolean,
    ): Promise<string> => {
      const manager = PDFManagerFactory.getPDFDocManager();
      if (!manager) return Promise.reject(new Error('invalid manager instance'));
      const annotation = new (manager.createZoneAnnotation(instance))(zoneId, editable); // Specify zoneId
      annotation.setCustomData(ZoneCustomData.zoneAnnotation, 'true');
      annotation.Id = annotationMarkup.annotationId;
      annotation.PageNumber = annotationMarkup.page;
      if (annotationMarkup.quads) {
        (annotationMarkup.quads as Quad[]).forEach((quad) => {
          annotation.Quads.push(
            new instance.Core.Math.Quad(quad.x1, quad.y1, quad.x2, quad.y2, quad.x3, quad.y3, quad.x4, quad.y4),
          );
        });
        const dimensionFromQuads = quadsToDimensions(annotation.Quads);
        annotation.setRect(
          new instance.Core.Math.Rect(
            dimensionFromQuads.X,
            dimensionFromQuads.Y,
            dimensionFromQuads.X + dimensionFromQuads.Width,
            dimensionFromQuads.Y + dimensionFromQuads.Height,
          ),
        );
      }

      annotation.ReadOnly = editable;
      annotation.setCustomData(AnnotationCustomData.drawMarkups, 'true');
      annotation.setCustomData(ZoneCustomData.confirmed, 'true');
      annotationManager.addAnnotation(annotation);
      // force redraw to avoid weird behaviors caused by loading time
      annotationManager.redrawAnnotation(annotation);
      const rect = annotation.getRect();

      return PDFTronManager.extractText(document, annotationMarkup.page, rect.x1, rect.y1, rect.x2, rect.y2);
    };

    // Loop through zone annotations in store for source document
    const state = store.getState();
    let zoneIdCount = 0;
    state.inspection.source.annotations
      .filter((annot) => annot.usedTool === PDFTronTools.ZONE) // Only zones
      .forEach(async (markup, index) => {
        // We need to check if the annotation is already there so we won't be adding it again
        const existingAnnotation = sourceAnnotManager.getAnnotationById(markup.annotationId);
        if (existingAnnotation) return;
        zoneIdCount++;
        const zoneId = zoneIdCount;

        // Get related annotation on target document
        const targetMarkup = state.inspection.target.annotations.find(
          (annot) => annot.annotationId.split('_')[0] === markup.annotationId,
        );
        if (!targetMarkup) return; // Should not occur!
        // Here we need to create the two annotations and get their associated texts to complete the restore
        const sourceContent = await createAnnotationPromise(
          zoneId,
          markup,
          sourceAnnotManager,
          sourceInstance,
          sourceDocument,
          false,
        );
        const targetContent = await createAnnotationPromise(
          zoneId,
          targetMarkup,
          targetAnnotManager,
          targetInstance,
          targetDocument,
          true,
        );

        if (!state.inspection.source.zoneSelectedTexts[index]) {
          // We now have both text samples, dispatch the needed Redux actions.
          // Set the selected text on source
          dispatch(
            inspection.actions.addZoneSelectedTexts({
              documentType: DocumentTypes.source,
              selectedText: sourceContent,
              zoneId: `${zoneId}`,
              annotationId: markup.annotationId,
            }),
          );
          // Set the 'searched' annotation of target
          const searchedAnnotation = {
            annotationId: targetMarkup.annotationId,
            text: targetContent,
            zoneId: Number(zoneId),
          };
          dispatch(
            inspection.actions.addZoneSearchedAnnotation({
              searchedAnnotation,
            }),
          );
          // And finally set the link to the zone on the target document
          dispatch(inspection.actions.setDrawZoneSelected({ index }));
        }
      });
    dispatch(inspection.actions.setTextZoneId(zoneIdCount + 1));
  }

  public static drawAllTexts(differences: Array<Difference>, document: DocumentType): void {
    const instance = PDFTronManager.getPDFInstance(document);
    if (!instance) return;

    const instanceAnnotationManager = instance.Core.documentViewer.getAnnotationManager();
    if (!instanceAnnotationManager) return;

    const diffToAnnot = new Map<string, Core.Annotations.Annotation | null>();

    // add differences and their subdifferences to map
    differences.forEach((diff) => {
      diffToAnnot.set(diff.id, null);
      if (diff.subDiff.length) {
        diff.subDiff.forEach((subDiff) => {
          diffToAnnot.set(subDiff.id, null);
        });
      }
    });

    PDFAnnotationManager.drawDifferences(instanceAnnotationManager, document, diffToAnnot, differences);
  }

  private static drawDifferences(
    annotManager: Core.AnnotationManager,
    documentType: DocumentTypes,
    diffToAnnot: Map<string, Core.Annotations.Annotation | null>,
    differences: Difference[],
  ) {
    const instance = PDFTronManager.getPDFInstance(documentType);
    if (!instance) return;

    const DifferenceAnnotation = DifferenceClass.createDifferenceAnnotation(instance, documentType);

    const toDelete: Core.Annotations.Annotation[] = [];
    const toAdd: Core.Annotations.Annotation[] = [];
    const currentAnnotations = annotManager.getAnnotationsList();
    const totalPages = getTotalPageCount(store.getState())[documentType];
    // This exists to be used by redrawAnnotation. redrawAnnotation redraws all annoations on the same page so we only need to pass it one annotation on each page.
    const firstAnnotationByPage: (Core.Annotations.Annotation | null)[] = [...Array(totalPages)].map((index) => null);

    let differenceFilters = getDifferenceFilters(store.getState());

    // set any existing annotations in the map of differences
    currentAnnotations.forEach((annot) => {
      if (diffToAnnot.has(annot.Id)) {
        diffToAnnot.set(annot.Id, annot);
      }
    });

    differences.forEach((diff) => {
      const annotation = diffToAnnot.get(diff.id);
      const page = diff[documentType].location.pageNumber;
      if (annotation) {
        if (diff.excluded || differenceFilters?.[diff.type]) {
          // annotation exists and should be deleted
          toDelete.push(annotation);
          if (!firstAnnotationByPage[page]) {
            firstAnnotationByPage[page] = annotation;
          }
          // look for subdifference if need to remove
          if (diff.subDiff.length) {
            diff.subDiff.forEach((subDiff) => {
              const subDiffAnnotation = diffToAnnot.get(subDiff.id);
              if (subDiffAnnotation) {
                toDelete.push(subDiffAnnotation);
              }
            });
          }
        }
        if (diff.type === 'Deletion' && DocumentTypes.target === documentType) {
          // Deletion annoation marker edit as it doesn't get deleted/added so its still the old one before the update.
          // If you delete it and draw a new one, it de-selects it. This is a workaround deselecting.
          annotation.setX(diff.target.location.rect[0]);
          annotation.setY(diff.target.location.rect[1]);
        }
      } else if (!diff.excluded && !differenceFilters?.[diff.type]) {
        // annotation does not exist and should be created
        const newAnnotation = new DifferenceAnnotation({
          annotData: {
            ...diff[documentType],
            transformCoord: diff.transformCoord || false,
          },
          differenceId: diff.id,
          type: diff.type,
          groupId: diff.groupId,
        });
        toAdd.push(newAnnotation);
        if (!firstAnnotationByPage[page]) {
          firstAnnotationByPage[page] = newAnnotation;
        }
        if (documentType === DocumentTypes.target && diff.subDiff.length) {
          diff.subDiff.forEach((subDiff) => {
            const subDiffAnnotation = diffToAnnot.get(subDiff.id);
            if (!subDiffAnnotation) {
              // annotations are not visible if they are 0, 0 on top left
              const rect = subDiff[documentType].location.rect.map((r) => Math.max(2, r));
              const correctedDiff = {
                ...subDiff[documentType],
                location: { ...subDiff[documentType].location, rect: rect },
              };
              const newSubDiffAnnotation = new DifferenceAnnotation({
                annotData: {
                  ...correctedDiff,
                  transformCoord: subDiff.transformCoord || false,
                },
                differenceId: subDiff.id,
                type: subDiff.type,
              });
              newSubDiffAnnotation.NoView = false;
              newSubDiffAnnotation.setCustomData(SubDifferenceCustomData.subDifferenceParentId, subDiff.parentId);
              newSubDiffAnnotation.setCustomData('parentType', diff.type);

              toAdd.push(newSubDiffAnnotation);
            }
          });
        }
      }
    });
    if (toDelete.length) {
      annotManager.deleteAnnotations(toDelete, { imported: true });
    }
    if (toAdd.length) {
      annotManager.addAnnotations(toAdd);
    }

    firstAnnotationByPage.forEach((annot) => {
      if (annot) {
        annotManager.redrawAnnotation(annot);
      }
    });
  }

  public static deleteRectangleAnnotation(differenceId: string): void {
    const deleteDocumentAnnotation = (documentType: DocumentType, diffId: string) => {
      const annotManager = PDFAnnotationManager.getInstance(documentType);
      if (!annotManager) return;

      const annot = annotManager.getAnnotationById(diffId);
      if (annot) {
        annotManager.deleteAnnotation(annot, { imported: true });
      }
    };

    deleteDocumentAnnotation(DocumentTypes.source, differenceId);
    deleteDocumentAnnotation(DocumentTypes.target, differenceId);
  }

  public static redrawDifferenceBoxes(): void {
    [DocumentTypes.source, DocumentTypes.target].forEach((document) => {
      const instance = PDFAnnotationManager.getInstance(document);
      if (!instance) return;
      const annotationList = instance.getAnnotationsList();
      const annotManager = PDFAnnotationManager.getInstance(document);
      if (!annotManager) return;
      annotationList.forEach((annot) => {
        if (annot.getCustomData('difference') === 'true') {
          annotManager?.redrawAnnotation(annot);
        }
      });
    });
    this.redrawSelectedDifferenceOverlay();
  }

  public static redrawSelectedDifferenceOverlay(): void {
    const state = store.getState();
    const docManager = PDFManagerFactory.getPDFDocManager();
    if (!docManager) return;
    const focusedDifference = getFocusedDifference(state);
    if (!focusedDifference) return;
    [DocumentTypes.source, DocumentTypes.target].forEach((document) => {
      const instance = PDFAnnotationManager.getInstance(document);
      if (!instance) return;
      const annotManager = PDFAnnotationManager.getInstance(document);
      if (!annotManager) return;

      // Get the selected group
      const groupOrDifference = getGroupByDifference(focusedDifference.id)(state);
      const differencesInGroup = getGroupedDifferences(groupOrDifference)(state);
      // Redraw the overlay
      if (differencesInGroup) {
        docManager?.[document]?.highContrastAnnotations(differencesInGroup);
      }
    });
    // Reset flashing for graphic differences
    if (focusedDifference.type === DifferenceTypeNames.Graphics) {
      docManager.GraphicDifferenceFocused([focusedDifference]);
    }
  }

  public static changeDocumentAnnotationVisibility(
    document: DocumentTypes,
    differences: Difference[],
    display = true,
    draw = true,
  ) {
    const instance = PDFAnnotationManager.getInstance(document);

    const annotations: Core.Annotations.Annotation[] = [];
    const newAnnotations: Difference[] = [];
    let annotation: Core.Annotations.Annotation | undefined;
    differences.forEach((diff) => {
      annotation = instance?.getAnnotationById(diff.id);
      if (annotation) {
        if (document === DocumentTypes.source) {
          annotations.push(annotation);
        } else if (document === DocumentTypes.target && diff.type !== DifferenceTypeNames.Deletion) {
          annotations.push(annotation);
        }
      } else if (diff) {
        newAnnotations.push(diff);
      }

      diff.subDiff.forEach((subDiff) => {
        annotation = instance?.getAnnotationById(subDiff.id);
        if (annotation) {
          annotations.push(annotation);
        }
      });
    });

    if (display) {
      // if the annotations are not already in the docs we need to draw them otherwise we just change visibility
      instance?.showAnnotations(annotations);

      if (draw) {
        PDFAnnotationManager.drawAllTexts(newAnnotations, document);
      }
    } else {
      instance?.hideAnnotations(annotations);
    }
  }

  public static changeDocumentsAnnotationVisibility(differences: Difference[], display = true, draw = true): void {
    this.changeDocumentAnnotationVisibility(DocumentTypes.source, differences, display, draw);
    this.changeDocumentAnnotationVisibility(DocumentTypes.target, differences, display, draw);
  }

  public static getSelectedId(): string {
    return this.selectedId;
  }
}
