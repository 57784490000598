import { Grid, Theme, Typography, List } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import { useLocation } from 'react-router-dom';
import { getStyleVariables } from 'styles/vars';
import { BookmarksOutlined, LockRounded, FolderShared } from '@mui/icons-material';
import { isSuperAdmin, isAdmin, isTenantAdmin, isTenantManager, isCustomProfiler } from 'utils/auth';
import MenuListItem from './DropdownMenuItem';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';
import { useSuggestedWords } from 'store/queries/dictionaries/suggestedWords';
import GVChip, { GVChipColor, GVChipSize } from 'components/GVChip/GVChip';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    drawerContainer: {
      height: 'inherit',
      width: styleVariables.myaccount.menuWidth,
      backgroundColor: styleVariables.myaccount.menuBackground,
      paddingTop: theme.spacing(4),
    },
    title: {
      marginLeft: theme.spacing(2),
    },
    list: {
      cursor: 'pointer',
    },
    miniChip: {
      position: 'absolute',
      left: theme.spacing(0.5),
      top: theme.spacing(3),
      marginLeft: theme.spacing(0.5),
    },
  };
});

const MyAccountMenu = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const { user } = useUserStateStore();
  if (!user) return null;

  const superPermissions = isSuperAdmin(user);
  const adminPermissions = superPermissions || isAdmin(user) || isTenantAdmin(user);
  const tenantPermissions = superPermissions || isTenantManager(user);
  const customProfilePermissions = isCustomProfiler(user);

  const { suggestedWordsList } = useSuggestedWords();
  const suggestedWordsCount = suggestedWordsList.length;
  const displayBreadcrumb = adminPermissions && suggestedWordsCount > 0;

  return (
    <Grid container direction="column" className={classes.drawerContainer}>
      <Grid item className={classes.title}>
        <Typography variant="h4" id="my-account">
          My Account
        </Typography>
      </Grid>
      <Grid item>
        <List className={classes.list}>
          <MenuListItem
            test_id="tab_settings"
            text="Settings"
            url="settings"
            icon={<SettingsOutlinedIcon />}
            selected={!!location.pathname.includes('settings')}
          />
          {adminPermissions && (
            <>
              <MenuListItem
                test_id="tab_my_team"
                text="My Team"
                url="myteam"
                icon={<AccountCircleOutlinedIcon />}
                selected={!!location.pathname.includes('myteam')}
              />
              <MenuListItem
                test_id="tab_audit_trail"
                text="Audit Trail"
                url="audittrail"
                icon={<PlaylistAddCheckIcon />}
                selected={!!location.pathname.includes('audittrail')}
              />
            </>
          )}
          {tenantPermissions && (
            <MenuListItem
              test_id="tab_tenant_management"
              text="Tenant Management"
              url="tenantmanagement"
              icon={<SupervisedUserCircleOutlinedIcon />}
              selected={!!location.pathname.includes('tenantmanagement')}
            />
          )}
          {adminPermissions && (
            <>
              <MenuListItem
                test_id="tab_custom_dictionaries"
                text="Custom Dictionaries"
                url="customdictionaries"
                icon={
                  <>
                    {displayBreadcrumb && !location.pathname.includes('customdictionaries') && (
                      <GVChip
                        className={classes.miniChip}
                        chipSize={GVChipSize.XSMALL}
                        chipColor={GVChipColor.ALERT}
                        circle
                      />
                    )}
                    <BookmarksOutlined />
                  </>
                }
                selected={location.pathname.includes('customdictionaries')}
              />
            </>
          )}
          {superPermissions && (
            <MenuListItem
              test_id="tab_internal_api"
              text="API"
              url="internalapi"
              icon={<LockRounded />}
              selected={!!location.pathname.includes('internalapi')}
            />
          )}
          {customProfilePermissions && (
            <MenuListItem
              test_id="tab_custom_profiles"
              text="Custom Profiles"
              url="customprofiles"
              icon={<FolderShared />}
              selected={!!location.pathname.includes('customprofiles')}
            />
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default MyAccountMenu;
