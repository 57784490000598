import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Theme, CircularProgress, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  getInspectionId,
  getTextProcessStatus,
  getUnconfirmTextZoneError,
  getUploadedFilesSelector,
  inspection,
  getStartingInspection,
  app,
  getName,
  getNoResultAnnotationId,
  getZoneSelectedTexts,
  getExcludedPage,
  getUnconfirmGraphicZoneError,
  getUnconfirmedMarqueeZoneError,
  getNumberOfAnnotations,
  getSelectedTool,
  getSpellingTrackingData,
  getManualSelectedZoneId,
  getInspectionSettings,
  getInvalidScaledGraphicZoneRatio,
  getAutoMatchGraphic,
  getSourceLayers,
  getTargetLayers,
  getSourceSeparations,
  getTargetSeparations,
  getMultiFileNamesJoined,
  getIsSingleFile,
  getSelectedCustomDictionaryIDs,
  getInspectionHasOutdatedGraphicZones,
  getHighSensitivitySelected,
  getOneToOneSelected,
  getPagesCropZones,
  getPendingCropZone,
  getPendingShapesRequest,
} from 'store';
import { startInspection, selectUnconfirmedZones } from 'store/requests';
import { GVActionButton, GVInspectionPanel } from 'components/common';
import { GVIcon } from 'components/icons';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { DocumentTypes, PDFTronTools, PDFTRON_DEFAULT_TOOL, AnnotationCustomData, StoreState } from 'types';
import { useConfirmation } from 'utils';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import { getStyleVariables } from 'styles/vars';
import { PDFManagerFactory, useAnnotationTools } from 'pdftron';
import GVTextButton from 'components/lib/GVTextButton/GVTextButton';
import { resetRequests } from '@redux-requests/core';
import { fetchDifferences } from 'store/request/differences/actions';
import { useTracker } from '../../Tracker/TrackerProvider';
import AnnotationsTools, { toggleTool } from './AnnotationsTools';
import { makeStyles } from 'tss-react/mui';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';
import { startShapesJob } from 'store/request/files/actions';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      height: `calc(100% - ${styleVariables.leftPanel.tabsHeight})`,
    },
    content: {
      height: '100%',
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    title: {
      padding: theme.spacing(4, 2),
    },
    leftPadding: {
      paddingLeft: theme.spacing(2),
    },
    buttonGrid: {
      bottom: 0,
      minWidth: '100%',
      minHeight: '100px',
      overflow: 'hidden',
      width: '100%',
      boxShadow: `0px -4px 10px ${styleVariables.colors.mainBackgroundColor}`,
      zIndex: 1,
    },
    icon: {
      width: theme.spacing(2.8),
      height: 'auto',
      marginRight: theme.spacing(0.5),
    },
    textButton: {
      marginRight: theme.spacing(2),
    },
    // MuiAlert is not yet part of the DeprecatedThemeOptions.Override type so we can't add it in the customizedTheme
    alert: {
      borderRadius: 0,
    },
    inspectionOverflow: {
      overflowX: 'hidden',
      overflowY: 'auto',
      marginTop: theme.spacing(1),
    },
    scrollBar: styleVariables.scrollBar,
  };
});

interface PrepTabProps {
  alertText?: (string | JSX.Element)[] | string;
}

const PrepTab = (props: PrepTabProps) => {
  const { alertText } = props;
  const tracker = useTracker();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const textProcessCompleted = useSelector(getTextProcessStatus);
  const inspectionId = useSelector(getInspectionId);
  const inspectionName = useSelector(getName);
  const unconfirmTextZoneError = useSelector(getUnconfirmTextZoneError);
  const unconfirmGraphicZoneError = useSelector(getUnconfirmGraphicZoneError);
  const unconfirmMarqueeZoneError = useSelector(getUnconfirmedMarqueeZoneError);
  const pendingCropZone = useSelector(getPendingCropZone);
  const pendingShapeRequest = useSelector(getPendingShapesRequest);
  const noResultAnnotationId = useSelector(getNoResultAnnotationId);
  const zoneSelectedTexts = useSelector(getZoneSelectedTexts);
  const excludedPage = useSelector(getExcludedPage);
  const confirm = useConfirmation();
  const startingInspection = useSelector(getStartingInspection);
  const numberOfAnnotations = useSelector(getNumberOfAnnotations);
  const spellingTrackingData = useSelector(getSpellingTrackingData);
  const { source, target } = useSelector(getUploadedFilesSelector);
  const selectedTool = useSelector(getSelectedTool);
  const manualSelectedZoneId = useSelector(getManualSelectedZoneId);
  const settings = useSelector(getInspectionSettings);
  const autoMatchGraphic = useSelector(getAutoMatchGraphic);
  const { customDictionaryList } = useCustomDictionaries({ getAll: true }); // this is used only because of tracking. The actual call that lists is in the dictionary select component
  const outdatedGraphicZones = useSelector(getInspectionHasOutdatedGraphicZones);
  const oneToOneSelected = useSelector(getOneToOneSelected);
  const highSensitivity = useSelector(getHighSensitivitySelected);
  const crops = useSelector(getPagesCropZones);

  const customDictionaryNames = useSelector(getSelectedCustomDictionaryIDs).map(
    (id) => customDictionaryList.find((dictionary) => dictionary.id === id)?.name,
  );
  const invalidScaledGraphicZoneRatio = useSelector(getInvalidScaledGraphicZoneRatio);
  const isSingleFile = useSelector(getIsSingleFile);
  const [alertOpen, setAlertOpen] = useState(false);

  useAnnotationTools();

  const PDFDocManager = PDFManagerFactory.getPDFDocManager();
  const checkUnconfirmedGraphicZone = PDFDocManager?.checkUnconfirmedGraphicZones();
  const hasUnconfirmedZones = PDFDocManager?.hasUnconfirmedZones() || false;
  const hasConfirmedGraphicZones = PDFDocManager?.hasConfirmedGraphicZones();

  const sourceInstance = PDFDocManager?.getInstance(DocumentTypes.source);
  const newInstance = PDFDocManager?.getInstance(DocumentTypes.target);
  const sourceHiddenLayers =
    useSelector(getSourceLayers)
      ?.filter((layer) => !layer.visible)
      .map((layer) => layer.name) || [];
  const newHiddenLayers =
    useSelector(getTargetLayers)
      ?.filter((layer) => !layer.visible)
      .map((layer) => layer.name) || [];
  const sourceHiddenSeparations =
    useSelector(getSourceSeparations)
      ?.filter((separation) => !separation.enabled)
      .map((separation) => separation.name) || [];
  const newHiddenSeparations =
    useSelector(getTargetSeparations)
      ?.filter((separation) => !separation.enabled)
      .map((separation) => separation.name) || [];
  const mergedFileNamesTarget = useSelector(getMultiFileNamesJoined(DocumentTypes.target));
  const mergedFileNamesSource = useSelector(getMultiFileNamesJoined(DocumentTypes.source));
  const sendTrackerNotification = async () => {
    const sourcePageQty = sourceInstance?.getTotalPages();
    const sourceFileSize = await sourceInstance?.docViewer.getDocument().getFileSize();
    const newPageQty = newInstance?.getTotalPages();
    const newFileSize = await newInstance?.docViewer.getDocument().getFileSize();
    tracker.track({
      name: 'Document-inspection',
      data: {
        sourceFileName: source.originalName || mergedFileNamesSource,
        newFileName: target.originalName || mergedFileNamesTarget,
        sourceHiddenLayers,
        newHiddenLayers,
        sourceHiddenSeparations,
        newHiddenSeparations,
        sourcePageQty,
        newPageQty,
        sourceFileSize: !isSingleFile ? sourceFileSize : '',
        newFileSize,
        inspectionName,
        sourceExcludedPage: excludedPage.source,
        newExcludedPage: excludedPage.target,
        sourceOCR: !!source.isOCR,
        newOCR: !!target.isOCR,
        fullPageGraphics: settings?.fullPageGraphics,
        shiftedGraphicsAnnotationsQty: numberOfAnnotations[PDFTronTools.GRAPHIC].source.shifted || 0,
        scaledGraphicsAnnotationQty: numberOfAnnotations[PDFTronTools.GRAPHIC].source.scaled || 0,
        sourceCrossoutAnnotationsQty: numberOfAnnotations[PDFTronTools.CROSSOUT].source || 0,
        newCrossoutAnnotationsQty: numberOfAnnotations[PDFTronTools.CROSSOUT].target || 0,
        textZoneAnnotationsQty: numberOfAnnotations[PDFTronTools.ZONE].source || 0,
        marqueeZoneAnnotationsQty: numberOfAnnotations[PDFTronTools.MARQUEE_ZONE].source || 0,
        sourceCropToolAnnotationsQty: numberOfAnnotations[PDFTronTools.CROP].source || 0,
        targetCropToolAnnotationsQty: numberOfAnnotations[PDFTronTools.CROP].target || 0,
        barcodeInspection: settings?.barcode || false,
        brailleLanguage: settings?.brailleLanguage,
        brailleInspection: !!settings?.brailleLanguage,
        inspectionMode: isSingleFile ? 'proofread' : 'compare',
        spellCheck: spellingTrackingData,
        customDict: customDictionaryNames,
        autoMatchFail: autoMatchGraphic?.autoMatchFail,
        autoMatchPass: autoMatchGraphic?.autoMatchPass,
        autoGraphicsAnnotationsQty: numberOfAnnotations[PDFTronTools.GRAPHIC].source.autoGraphic || 0,
        matchedGraphicsAnnotationsQty: numberOfAnnotations[PDFTronTools.GRAPHIC].source.matchedGraphic || 0,
        graphics: !!settings.graphics,
        sourceGraphicsCrop: crops.source.length,
        newGraphicsCrop: crops.target.length,
        sensitivityGraphics: highSensitivity ? 'High' : 'Low',
        sortedGraphics: !!oneToOneSelected,
        text: !!settings.text,
      },
    });
  };

  const handleStartInspection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (outdatedGraphicZones) dispatch(inspection.actions.deleteOutdatedGraphicZones()); // Crop tool is enabled but old graphic zones still exist

    if (!hasUnconfirmedZones && !checkUnconfirmedGraphicZone) {
      if (inspectionId) {
        sendTrackerNotification();
        dispatch(resetRequests([fetchDifferences.toString()]));
        PDFManagerFactory.getPDFDocManager()?.cleanDocumentHighlight();
        PDFTronManager.setToolMode(PDFTRON_DEFAULT_TOOL);
        dispatch(app.actions.setStartingInspection(true));
        dispatch(startInspection());
        dispatch(inspection.actions.setOpenPanel(false));
        // just so when going back to prep page, auto selection is still working
        dispatch(inspection.actions.selectingZone(false));
        dispatch(inspection.actions.setSelectedTool({ tool: PDFTRON_DEFAULT_TOOL }));
        dispatch(app.actions.clearDialogsFromState());
      }
    } else {
      confirm({
        variant: 'info',
        catchOnCancel: false,
        title: 'Unconfirmed Zone',
        description: checkUnconfirmedGraphicZone
          ? 'Please confirm your graphic zones before inspecting the files.'
          : 'Please confirm your match before inspecting the files.',
      });
      if (checkUnconfirmedGraphicZone) {
        PDFManagerFactory.getPDFDocManager()?.selectUnconfirmedGraphicZone();
      } else {
        PDFAnnotationManager.getInstance(DocumentTypes.source)?.deselectAllAnnotations();
        PDFAnnotationManager.getInstance(DocumentTypes.target)?.deselectAllAnnotations();
        dispatch(selectUnconfirmedZones());
      }
    }
  };

  const handleMarqueeToolButtonClicked = (tool: PDFTronTools, noResultId: string) => {
    PDFAnnotationManager.deleteAnnotationById(DocumentTypes.source, noResultId);
    dispatch(inspection.actions.setSelectedTool({ tool: toggleTool(tool, selectedTool, manualSelectedZoneId) }));
    const instance = PDFManagerFactory.getPDFDocManager()?.source;
    if (instance) {
      instance.setTool(PDFTronTools.MARQUEE_ZONE);
      /* eslint-disable */
      // @ts-ignore
      instance.instance.Core.Tools.TextTool.SELECTION_MODE = 'rectangular';
      // Switching tool type so we need to decrease text zone id
      dispatch(inspection.actions.decreaseTextZoneId());
    }
  };

  const handleManualTextSelectionButtonClicked = (noResultId: string) => {
    const zone = zoneSelectedTexts.find((zoneTexts) => {
      return zoneTexts.annotationId === noResultId;
    });
    dispatch(
      inspection.actions.activateManualSelection({
        annotationId: noResultId,
        zoneId: Number(zone?.zoneId),
      }),
    );

    const targetInstance = PDFTronManager.getPDFInstance(DocumentTypes.target);
    targetInstance?.UI.setToolMode(PDFTronTools.ZONE);
    const sourceInstance = PDFTronManager.getPDFInstance(DocumentTypes.source);
    sourceInstance?.UI.setToolMode(PDFTRON_DEFAULT_TOOL);
    PDFManagerFactory.getViewer(DocumentTypes.source)?.removeAnnotationDocumentHighlight();
    PDFManagerFactory.getViewer(DocumentTypes.target)?.addDocumentHighlight();
  };

  const renderConfirmationActions = (noResultId: string) => {
    return (
      <>
        <Grid item className={classes.textButton}>
          <GVTextButton
            id="manual-select-button"
            text="Manual Selection"
            onClick={() => handleManualTextSelectionButtonClicked(noResultId)}
          />
        </Grid>
        <Grid item>
          <Button
            id="marquee-zone-button"
            color="secondary"
            variant="contained"
            onClick={() => handleMarqueeToolButtonClicked(PDFTronTools.MARQUEE_ZONE, noResultId)}
          >
            Marquee Selection
          </Button>
        </Grid>
      </>
    );
  };

  const handleKeepCropsSelection = (keepCrops: boolean) => {
    if (!pendingShapeRequest) return;
    const { fileId, documentType, type, layerOrSeparationName } = pendingShapeRequest;

    if (keepCrops) {
      dispatch(
        inspection.actions.setPolygons({
          documentType,
          points: '',
          type: '',
          key: '',
          layerOrSeparationName: '',
        }),
      );
      dispatch(inspection.actions.setPendingShapeRequest(null));
    } else {
      const pdfDocMgr = PDFManagerFactory.getPDFDocManager();
      pdfDocMgr?.deleteAllCropZones(documentType);
      dispatch(startShapesJob(fileId, documentType, type, layerOrSeparationName));
      dispatch(inspection.actions.setPendingShapeRequest(null));
    }
  };

  const handleKeepShapesSelection = (keepShapes: boolean) => {
    if (!pendingCropZone) return;
    const { documentType, pendingCropId } = pendingCropZone;

    if (keepShapes) {
      PDFAnnotationManager.deleteAnnotationById(documentType as DocumentTypes, pendingCropId);
      const pdfDocMgr = PDFManagerFactory.getPDFDocManager();
      pdfDocMgr?.updateInputAnnotations();
      pdfDocMgr?.redrawInputAnnotations();
      dispatch(inspection.actions.setPendingCropZone({ documentType: null, pendingCropId: '' }));
    } else {
      dispatch(
        inspection.actions.setPolygons({
          documentType: documentType as DocumentTypes,
          points: '',
          key: '',
          type: '',
          layerOrSeparationName: '',
        }),
      );
      dispatch(inspection.actions.setPendingCropZone({ documentType: null, pendingCropId: '' }));
    }
  };

  const renderDecisionButtons = (item: 'shape' | 'crop', handleSelection: (keep: boolean) => void) => {
    const KEEP_ITEM = true;
    return (
      <>
        <Grid item className={classes.textButton}>
          <GVTextButton
            id={`keep-${item}-button`}
            text={item === 'shape' ? `Keep my shape selection` : `Keep my crop`}
            onClick={() => handleSelection(KEEP_ITEM)}
          />
        </Grid>
        <Grid item>
          <GVTextButton
            id={`override-${item}-button`}
            text={item === 'shape' ? `Replace my shape selection` : `Replace my crop`}
            onClick={() => handleSelection(!KEEP_ITEM)}
          />
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (unconfirmMarqueeZoneError) {
      confirm({
        variant: 'info',
        catchOnCancel: true,
        title: 'Unconfirmed Zone',
        description: 'Please confirm your match before creating or unmatching a new one.',
      })
        .then(() => {
          dispatch(inspection.actions.setUnconfirmedMarqueeZoneError(false));
          PDFManagerFactory.getPDFDocManager()?.selectUnconfirmedMarqueeZone();
        })
        .catch(() => {
          dispatch(inspection.actions.setUnconfirmedMarqueeZoneError(false));
          PDFManagerFactory.getPDFDocManager()?.selectUnconfirmedMarqueeZone();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, unconfirmMarqueeZoneError]);

  useEffect(() => {
    if (unconfirmTextZoneError) {
      confirm({
        variant: 'info',
        catchOnCancel: true,
        title: 'Unconfirmed Zone',
        description: 'Please confirm your match before creating or unmatching a new one.',
      })
        .then(() => {
          dispatch(inspection.actions.setUnconfirmTextZoneError(false));
          dispatch(selectUnconfirmedZones());
        })
        .catch(() => {
          dispatch(inspection.actions.setUnconfirmTextZoneError(false));
          dispatch(selectUnconfirmedZones());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, unconfirmTextZoneError]);

  useEffect(() => {
    if (unconfirmGraphicZoneError) {
      confirm({
        variant: 'info',
        catchOnCancel: true,
        title: 'Unconfirmed Zone',
        description: 'Please confirm the graphic zone before creating a new one.',
      })
        .then(() => {
          dispatch(inspection.actions.setUnconfirmGraphicZoneError(false));
          PDFManagerFactory.getPDFDocManager()?.selectUnconfirmedGraphicZone();
        })
        .catch(() => {
          dispatch(inspection.actions.setUnconfirmGraphicZoneError(false));
          PDFManagerFactory.getPDFDocManager()?.selectUnconfirmedGraphicZone();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, unconfirmGraphicZoneError]);

  useEffect(() => {
    if (pendingCropZone.pendingCropId) {
      confirm({
        title: 'Deactivate shape?',
        variant: 'call-to-action',
        catchOnCancel: false,
        description:
          'Drawing a crop zone will remove the active shape from the Layers menu. Would you like to continue?',
        additionalActions: renderDecisionButtons('shape', handleKeepShapesSelection),
      })
        .catch((e) => {
          dispatch(inspection.actions.setPendingCropZone({ documentType: null, pendingCropId: '' }));
        })
        .finally(() => {
          dispatch(inspection.actions.setPendingCropZone({ documentType: null, pendingCropId: '' }));
        });
    }
  }, [pendingCropZone.pendingCropId]);

  useEffect(() => {
    if (pendingShapeRequest) {
      confirm({
        title: 'Delete crops?',
        variant: 'call-to-action',
        catchOnCancel: false,
        description: 'Activating this shape will remove your crop zones from the document. Would you like to continue?',
        additionalActions: renderDecisionButtons('crop', handleKeepCropsSelection),
      })
        .catch((e) => {
          dispatch(inspection.actions.setPendingShapeRequest(null));
        })
        .finally(() => {
          dispatch(inspection.actions.setPendingShapeRequest(null));
        });
    }
  }, [pendingShapeRequest?.fileId]);

  useEffect(() => {
    if (noResultAnnotationId) {
      confirm({
        title: 'No Match Found',
        variant: 'call-to-action',
        catchOnCancel: false,
        description:
          'We were unable to find a match for your selection. You can either manually select a match or toggle and use the marquee zone tool.',
        additionalActions: renderConfirmationActions(noResultAnnotationId),
      }).then(() => {
        const sourceAnnoManager = PDFAnnotationManager.getInstance(DocumentTypes.source);
        sourceAnnoManager?.selectAnnotation(sourceAnnoManager.getAnnotationById(noResultAnnotationId));
        dispatch(inspection.actions.setNoResultAnnotationId(''));
        // reset this state so auto selection won't beak
        dispatch(inspection.actions.selectingZone(false));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, noResultAnnotationId]);

  // reset zoneId if there's no zone in the list
  useEffect(() => {
    if (!zoneSelectedTexts.length) {
      dispatch(inspection.actions.setTextZoneId(1));
    }
  }, [dispatch, zoneSelectedTexts.length]);

  useEffect(() => {
    setAlertOpen(Boolean(alertText));
  }, [alertText]);

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <Grid item container direction="row" alignItems="center">
        {alertOpen && (
          <Alert
            className={classes.alert}
            severity="warning"
            data-testid="live_text_pop_up"
            action={
              <Button
                onClick={() => {
                  setAlertOpen(false);
                }}
                color="inherit"
                size="small"
              >
                DISMISS
              </Button>
            }
          >
            {alertText}
          </Alert>
        )}
      </Grid>
      <Grid item container direction="column" wrap="nowrap" className={classes.content}>
        <Grid item className={classes.title}>
          <canvas id="imgProcessing" style={{ display: 'none' }} />
          <Typography variant="h3" color="textPrimary">
            {isSingleFile ? 'Prepare your file for inspection' : 'Prepare your files for inspection'}
          </Typography>
        </Grid>
        <Grid item className={classes.leftPadding}>
          <AnnotationsTools />
        </Grid>
        <Grid item className={`${classes.inspectionOverflow} ${classes.scrollBar}`}>
          <GVInspectionPanel />
        </Grid>
      </Grid>
      <Grid item className={classes.buttonGrid}>
        <GVTooltip
          title={
            isSingleFile &&
            textProcessCompleted &&
            !settings.barcode &&
            !settings.brailleLanguage &&
            !settings.dictionaryName
              ? 'Enable Spellcheck, Barcode or Braille to run a single document inspection'
              : ''
          }
          placement="bottom"
        >
          <span>
            <GVActionButton
              label={
                startingInspection ? (
                  <CircularProgress size={32} />
                ) : (
                  <>
                    <GVIcon className={classes.icon} />
                    Inspect
                  </>
                )
              }
              onClick={handleStartInspection}
              disabled={
                !textProcessCompleted ||
                startingInspection ||
                (!settings.barcode &&
                  !settings.brailleLanguage &&
                  !settings.dictionaryName &&
                  !settings.fullPageGraphics &&
                  !settings.text &&
                  !hasConfirmedGraphicZones)
              }
            />
          </span>
        </GVTooltip>
      </Grid>
    </Grid>
  );
};

export default PrepTab;
