import { User } from '@auth0/auth0-react';

const getUserField = (user: User, field: string, defaultValue: any): typeof defaultValue => {
  const key = `https://${import.meta.env.VITE_AUTH0_DOMAIN}_${field}`;
  if (!user && !user[key]) return defaultValue;
  return user[key];
};

export const getRoles = (user: User) => {
  return getUserField(user, 'roles', []);
};

export const getUserMetadata = (user: User) => {
  return getUserField(user, 'user_metadata', {});
};

export const getAppMetadata = (user?: User | undefined): Record<string, any> => {
  if (!user) return {};
  return getUserField(user, 'app_metadata', {});
};

export const getCreatedAt = (user: User) => {
  return getUserField(user, 'created_at', '');
};

export const getTenantId = (user: User) => {
  const appMetadata: any = getAppMetadata(user);
  return appMetadata?.tenantInfo?.tenantId || '';
};

export const getOrganizationId = (user: User) => {
  const appMetadata: any = getAppMetadata(user);
  return appMetadata?.org_id || '';
};

export const getValidationError = (user?: User): boolean => {
  if (!user) return false;
  return getUserField(user, 'validate_error', false);
};

const hasRole = (user: User, role: string): boolean => {
  const roles = getRoles(user);
  return roles.includes(role);
};

export const isUser = (user: User): boolean => {
  return hasRole(user, 'user');
};

export const isAdmin = (user: User): boolean => {
  return hasRole(user, 'administrator');
};

export const isTenantManager = (user: User): boolean => {
  return hasRole(user, 'tenant_manager');
};

export const isSuperAdmin = (user: User): boolean => {
  return hasRole(user, 'superadmin');
};

export const isTenantAdmin = (user: User): boolean => {
  return hasRole(user, 'tenant_admin');
};

export const isCustomProfiler = (user: User): boolean => {
  return hasRole(user, 'custom_profiler');
};

export const getUserInfo = (user: User): any => {
  const roles = getRoles(user);
  const createdAt = getCreatedAt(user);
  return {
    ...user,
    roles,
    created_at: createdAt,
  };
};

export default {
  getRoles,
  getUserMetadata,
  getAppMetadata,
  getUserInfo,
};
