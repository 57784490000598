import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getConvertingFiles,
  getUploadedFilesSelector,
  getSnackMessage,
  files,
  getIsAiErrorOpen,
  app,
  getMultipleFileStatus,
  getFileRequestKeys,
  inspection,
} from 'store';
import { cancelUpload, fetchFile, finishFileCreation, startFileCreation } from 'store/requests';
import { useDropzone } from 'react-dropzone';
import { DocumentStates, DocumentTypes, FileErrors } from 'types';
import GVErrorDialog from 'components/common/GVErrorDialog/GVErrorDialog';
import {
  startBatchFileCreation,
  finishBatchFileCreation,
  fetchMultipleFiles,
  pollFilesStatus,
  pollFileStatus,
} from 'store/request/files/actions';
import { FailedFile } from 'store/request/files/types';
import { useQuery } from '@redux-requests/react';
import { stopPolling, abortRequests } from '@redux-requests/core';
import DragAndDropBackground from './DragAndDropBackground';
import UploadProgressBackground from './UploadProgressBackgound';
import { AxiosProgressEvent } from 'axios';
import fileActions from 'store/files/files';
import { computeSHA256 } from 'utils/computeChecksum';
interface PageBackgroundProps {
  document: DocumentTypes;
  title: string;
  dragging: boolean;
  setDragging: (value: React.SetStateAction<boolean>) => void;
  handleShowLibrary: (value?: boolean) => void;
}

const supportedFileTypes = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.doc', '.docx'],
  'application/vnd.ms-excel': ['.xls', '.xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
  'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.ppt', '.pptx'],
  'application/xml': ['.xml'],
  'application/postscript': ['.ai'],
  'application/vnd.adobe.illustrator': ['.ai'],
  'application/illustrator': ['.ai'],
  'application/rtf': ['.rtf'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/tiff': ['.tiff', '.tif'],
};

const PageBackground = (props: PageBackgroundProps) => {
  const { dragging, setDragging, handleShowLibrary } = props;
  const [uploading, setUploading] = useState<number | false>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const isAiError = useSelector(getIsAiErrorOpen);
  const convertingFiles = useSelector(getConvertingFiles);
  const filesState = useSelector(getUploadedFilesSelector);
  const errorMessage = useSelector(getSnackMessage);
  const multipleFileStatus = useSelector(getMultipleFileStatus)[props.document];
  const fileRequestKey = useSelector(getFileRequestKeys)[props.document];
  const pollFileStatusQuery = pollFileStatus.toString() + fileRequestKey;
  const dispatch = useDispatch();
  const { data: multipleFilesData } = useQuery({ type: fetchMultipleFiles });
  const { fileId } = filesState[props.document];

  const handleCancelUpload = () => {
    setUploading(false);
    setUploadProgress(0);
    dispatch(cancelUpload());
    dispatch(stopPolling([pollFilesStatus.toString()]));
    dispatch(stopPolling([pollFileStatusQuery]));
    dispatch(files.actions.setMultipleFileStatus({ documentType: props.document, status: false }));
    dispatch(
      inspection.actions.setLoadingState({ documentType: props.document, documentState: DocumentStates.INITIAL }),
    );
    dispatch(
      abortRequests([
        finishFileCreation.toString(),
        startFileCreation.toString(),
        startBatchFileCreation.toString(),
        finishBatchFileCreation.toString(),
        fetchFile.toString(),
        fetchMultipleFiles.toString(),
        pollFilesStatus.toString(),
        pollFileStatusQuery,
      ]),
    );
    dispatch(files.actions.removeFile({ documentType: props.document }));
    dispatch(files.actions.setFileCreationCancelled(true));
  };

  useEffect(() => {
    if (convertingFiles[props.document] && !isAiError) {
      dispatch(pollFileStatus(fileId, props.document));
    }
  }, [dispatch, convertingFiles, props.document, fileId, isAiError]);

  useEffect(() => {
    if (errorMessage?.open || isAiError) {
      setUploading(false);
      dispatch(files.actions.removeFile({ documentType: props.document }));
    }
  }, [dispatch, errorMessage, props.document, setUploading, isAiError]);

  useEffect(() => {
    if (!multipleFilesData && !multipleFileStatus) {
      setUploading(false);
    }
  }, [multipleFilesData]);

  const updateUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadProgress(progress >= 90 ? 90 : progress);
    }
  };

  const lowerCaseExtension = (name: string) => {
    const fileNameParts = name.split('.');
    const extension = fileNameParts.pop() || '';
    fileNameParts.push(extension.toLowerCase());
    return fileNameParts.join('.');
  };

  const dialogProps = {
    title: 'Error loading File',
    subTitle:
      'There was an error uploading your Illustrator file. Verify that your file was saved using compatiblity mode and please try again.',
    open: isAiError,
    buttonText: 'Dismiss',
    handleCallToActionClick: () => {
      dispatch(files.actions.setIsAiErrorOpen(false));
    },
    handleCloseModal: () => {
      dispatch(files.actions.setIsAiErrorOpen(false));
    },
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    accept: supportedFileTypes,
    onDrop: async (accepted, rejected) => {
      const fileDropCount = accepted.length + rejected.length; // how many files the user dropped

      if (fileDropCount > 10) {
        dispatch(
          app.actions.setSnackMessage({
            message: 'There is a maximum of 10 files that can be uploaded at one time',
            type: 'error',
          }),
        );
        return;
      }

      if (accepted.length === 0) {
        dispatch(
          app.actions.setSnackMessage({
            message: 'Unsupported file type.',
            type: 'error',
          }),
        );
        return;
      }

      const renamedAcceptedFiles = accepted.map(
        (file) => new File([file], lowerCaseExtension(file.name), { type: file.type }),
      );

      setUploading(renamedAcceptedFiles.length);
      setDragging(false);
      dispatch(fileActions.actions.setFileCreationCancelled(false));
      if (fileDropCount === 1) {
        // single file upload
        const file = renamedAcceptedFiles[0];
        const fileData = new FormData();
        const checksum = await computeSHA256(file as File);

        fileData.append('file', file);
        fileData.append('checksum', checksum);

        dispatch(startFileCreation(fileData, props.document, updateUploadProgress));
      } else if (fileDropCount > 1) {
        // multiple file upload
        const filesData = new FormData();

        await Promise.all(
          renamedAcceptedFiles.map(async (file) => {
            const checksum = await computeSHA256(file as File);
            return { file, checksum };
          }),
        ).then((results) => {
          results.forEach(({ file, checksum }) => {
            filesData.append('files', file);
            filesData.append('checksums', checksum);
          });
        });

        const rejectedFiles: FailedFile[] = rejected.map((rejectedFile, i) => ({
          id: String(i + 1),
          filename: lowerCaseExtension(rejectedFile.file.name),
          error: FileErrors.incompatibleFileType,
        }));

        dispatch(startBatchFileCreation(filesData, rejectedFiles, props.document, updateUploadProgress));
      }
    },
    onDragEnter: () => {
      setDragging(true);
    },
    onDragLeave: () => {
      setDragging(false);
    },
  });

  return (
    <>
      <GVErrorDialog {...dialogProps} />
      {uploading || convertingFiles[props.document] ? (
        <UploadProgressBackground
          document={props.document}
          uploadProgress={uploadProgress}
          handleCancelUpload={handleCancelUpload}
          convertingFiles={convertingFiles}
          uploadCount={uploading}
        />
      ) : (
        <DragAndDropBackground
          document={props.document}
          title={props.title}
          handleShowLibrary={handleShowLibrary}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          open={open}
          dragging={dragging}
        />
      )}
    </>
  );
};

export default PageBackground;
