/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */

import { all, takeEvery, put, select, debounce, call, take } from 'redux-saga/effects';
import { resetRequests, success } from '@redux-requests/core';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import {
  startFileCreation,
  fetchFile,
  fetchFileList,
  startInspection,
  resetInspection,
  selectUnconfirmedZones,
} from 'store/requests';
import {
  files,
  inspection,
  app,
  getUploadedFilesSelector,
  getInspectionId,
  getAutosaving,
  getGroupById,
  getUpdateInspectionData,
  getName,
  getPreviousDifferenceId,
  getFocusedDifference,
  getCreateInspectionData,
  getZoneSelectedTexts,
  getGroupedDifferences,
  getDocumentsLoaded,
  getInspectionSettings,
  getFocusedDifferenceId,
  getDisplayedDifferences,
  getHiddenDifferences,
  getSegmentDisplayedDifferenceCounts,
  getSegmentSmartDiscardedDifferencesCount,
  getFilesIds,
  getDifferences,
} from 'store';
import {
  DocumentType,
  DocumentTypes,
  InspectionStatuses,
  Difference,
  DifferenceTypeNames,
  FileStatus,
  UpdateInspectionData,
  SelectedText,
  InspectFileState,
  UserPreferences,
  FileErrors,
  MergedFileData,
  ToastStatuses,
  FilesIdData,
  Match,
} from 'types';
import PDFTronAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { PDFManagerFactory } from 'pdftron';
import docManagerSagas from 'pdftron/docManager/saga';
import { tableEnhancementFileCheck } from 'utils/fileTypeCheck/fileTypeCheck';
import { Core } from '@pdftron/webviewer';
import { shakeDocumentsZones, zoneTypeByToolName } from 'pdftron/docManager/AnnotationTools/AnnotationTools';
import { isReportsUrl, isResultUrl } from 'utils/location';
import toastEvents from 'components/DocumentToast/toastEvents';

import { AnnotationCustomData, Group, InputAnnotation, InspectionSettings } from 'types/inspection';
import Crossout from 'pdftron/docManager/AnnotationTools/Crossout';
import report from './report/reducer';
import reportSagas from './report/sagas';
import differencesSagas from './request/differences/sagas';

import {
  getFocusedMatchIndex,
  getShowLiveText,
  getSnackMessage,
  getToastStatuses,
  getUpdatingInspection,
  getUserPreferences,
} from './app/appSelectors';
import { updateUserData, addUser, updateUser } from './myAccount/requests';
import { trackTrackerEvent } from '../components/Tracker/TrackerProvider';
import auth0Sagas from './auth/auth0Saga';
import appSagas from './app/appSagas';
import { fetchDifferences, updateDifference } from './request/differences/actions';
import { fetchGroups, patchGroup } from './request/difference-group/actions';
import {
  getLiveTextNotFound,
  getExcludedPage,
  getAnnotations,
  getSpellingTrackingData,
  getNumberOfAnnotations,
  getAllPageLoadedSelector,
  getIsSingleFile,
  getLastJobMatches,
  getFlashMode,
} from './inspection/inspectionSelectors';
import {
  runInspection,
  createInspection,
  updateInspection,
  updateInspectionFiles,
  fetchInspection,
  pollInspectionStatus,
  CreateInspectionData,
} from './request/inspections/actions';
import { FileFlags } from './request/files/types';
import { GVSnackMessage } from 'components/lib/GVSnackbar/GVSnackbar';
import { useDocumentToastStateStore } from 'zstore/documentToastStateStore';
import { useZoomStateStore } from 'zstore/zoomStateStore';
import { CtfMatchResult } from './request/match/types';
import store from './store';

export interface FileData {
  id: string;
  filename: string;
  originalFilename: string;
  userId: string;
  path: string;
  status: FileStatus;
  createdAt: string;
  error?: FileErrors | null;
  mergedMetadata?: MergedFileData[];
  isOCR?: boolean;
  flags?: FileFlags[];
  originalFileId?: string;
}

interface FileUploadResult extends Action {
  payload: {
    data: FileData;
  };
  meta: {
    requestAction: {
      payload: {
        request: unknown;
      };
      meta: {
        document: DocumentType;
      };
    };
  };
}

interface FileListResult extends Action {
  payload: {
    data: Array<FileData>;
  };
}

interface ExcludePagePayload extends Action {
  payload: {
    documentType: DocumentType;
    pageNumber: number;
  };
}

interface FetchFilePayload extends Action {
  payload: {
    data: FileData;
  };
  meta: {
    document: DocumentType;
  };
}

function* handleFilesList(action: FileListResult) {
  const { data } = action.payload;

  const filesList = data.map((f) => ({
    fileId: f.id,
    url: `inspection-files/${f.path}`,
    status: f.status,
    fileName: f.filename,
    originalName: f.originalFilename,
    createdAt: f.createdAt,
    mergedFileData: f.mergedMetadata,
    isOCR: f.flags?.includes(FileFlags.ocr),
  }));

  yield put(files.actions.setFilesList(filesList));
}

function* saveInspection() {
  const id: string = yield select(getInspectionId);
  const updateData: UpdateInspectionData = yield select(getUpdateInspectionData);
  yield put(updateInspection(id, updateData));
  if (updateData.settings?.singleFile) {
    PDFTronManager.setPDFInstance(DocumentTypes.source, null);
  }

  // If we update anything related to markups the inspection has to run again if we click "inspect"
  yield put(inspection.actions.setInspectionData({ inspectionStatus: InspectionStatuses.idle }));
}

function* autoSaveInspection() {
  const hasLoaded: boolean = yield select(getAllPageLoadedSelector);
  const id: string = yield select(getInspectionId); // prevent saving while fetching the new inspection
  if (id && hasLoaded) {
    yield call(saveInspection);
  } else {
    yield put(app.actions.setAutosaving(false));
  }
}

// We need a separate function for updating the name because we won't change the inspection status.
function* autoSaveName() {
  const hasLoaded: boolean = yield select(getAllPageLoadedSelector);
  const id: string = yield select(getInspectionId); // prevent saving while fetching the new inspection
  const name: string = yield select(getName);
  if (id && hasLoaded) {
    yield put(updateInspection(id, { name }));
  } else {
    yield put(app.actions.setAutosaving(false));
  }
}

function* handleSetAutosavingFlag() {
  yield put(app.actions.setAutosaving(true));
}

function* handleSetDifferenceViewOptions() {
  // show all the annotations that are not filtered or excluded
  const displayDifferences: Difference[] = yield select(getDisplayedDifferences);

  // hide all the annotations that were filtered or excluded
  const hiddenDifferences: Difference[] = yield select(getHiddenDifferences);

  // check if the current selected difference is about to be filtered.
  const currentDifferenceId: string = yield select(getFocusedDifferenceId);
  if (hiddenDifferences.find((diff: Difference) => diff.id === currentDifferenceId)) {
    yield put(inspection.actions.unfocusDifference());
  }

  const isNotPrep = isResultUrl() || isReportsUrl(); // todo: maybe this makes no sense to also be passed to drawMarkups
  if (isNotPrep) {
    PDFTronAnnotationManager.changeDocumentsAnnotationVisibility(displayDifferences);
    PDFTronAnnotationManager.changeDocumentsAnnotationVisibility(hiddenDifferences, false);
  }
}

function* handleStartInspection() {
  const inspectionId: string = yield select(getInspectionId);
  const autosaving: boolean = yield select(getAutosaving);
  const noLiveText: boolean = yield select(getLiveTextNotFound);

  if (autosaving) {
    yield take(success(updateInspection.toString()));
  }

  yield put(
    runInspection(inspectionId, {
      noLiveText,
    }),
  );
}

// function* handleCancelInspection() {
//   const id = yield select(getInspectionId);
//   // For cancelling we will just put the status back to idle
//   // (the backend will ignore the inspection results if the inspection is set as Pending)

//   yield put(updateInspection(id, { status: InspectionStatuses.idle }));
//   yield put(app.actions.setStartingInspection(false));
// }
function* handleSingleFileInspection() {
  const filesId: FilesIdData = yield select(getFilesIds);
  const inspectionId: string = yield select(getInspectionId);

  if (filesId.masterFileId) {
    yield put(files.actions.transferSourceToTarget());
  }
  if (!inspectionId && (filesId.sampleFileId || filesId.masterFileId)) {
    const createInspectionData: CreateInspectionData = yield select(getCreateInspectionData);
    if (createInspectionData.settings?.singleFile && createInspectionData.masterFileId) {
      createInspectionData.sampleFileId = createInspectionData.masterFileId;
    }
    yield put(createInspection(createInspectionData));
  } else if (inspectionId) {
    const updateInspectionData: UpdateInspectionData = yield select(getUpdateInspectionData);
    if (updateInspectionData.settings?.singleFile && updateInspectionData.masterFileId) {
      updateInspectionData.sampleFileId = updateInspectionData.masterFileId;
    }
    yield put(updateInspectionFiles(inspectionId, updateInspectionData));
  }
}

function* handleFlashMode() {
  const docManager = PDFManagerFactory.getPDFDocManager();
  if (!docManager) return;
  docManager.resetAnimation();
  if (!getFlashMode(store.getState())) return;

  const focusedDifference: Difference = yield select(getFocusedDifference);
  const focusedMatchIndex: number = yield select(getFocusedMatchIndex);

  if (focusedDifference && focusedDifference.type === DifferenceTypeNames.Graphics) {
    docManager.GraphicDifferenceFocused([focusedDifference]);
    return;
  }
  if (focusedMatchIndex !== -1) {
    const lastJobMatches: CtfMatchResult[] = yield select(getLastJobMatches);
    const match = lastJobMatches.find((match: CtfMatchResult) => match.index === focusedMatchIndex);
    docManager.GraphicMatchFocused(match as CtfMatchResult);
  }
}

function* handleFocusMatch() {
  const focusedMatchIndex: number = yield select(getFocusedMatchIndex);
  const lastJobMatches: Match[] = yield select(getLastJobMatches);
  const docManager = PDFManagerFactory.getPDFDocManager();
  if (!docManager) return;

  if (focusedMatchIndex === -1) {
    docManager.resetAnimation();
    return;
  }

  docManager.GraphicMatchFocused(lastJobMatches[focusedMatchIndex]);
}

function* handleDifferenceBoxVisibility() {
  PDFTronAnnotationManager.redrawDifferenceBoxes();
}

function* handleOverlayVisibility() {
  const focusedDifference: Difference = yield select(getFocusedDifference);
  if (!focusedDifference) return;

  const docManager = PDFManagerFactory.getPDFDocManager();
  if (!docManager) return;

  docManager?.source?.handleOverlayVisibilityChange();
  docManager?.target?.handleOverlayVisibilityChange();
  PDFTronAnnotationManager.redrawSelectedDifferenceOverlay();
}

function* handleFocusDifference() {
  const { type, id: differenceId, groupId: focusedGroupId } = yield select(getFocusedDifference);
  const currentGroupedDifferences: ReturnType<ReturnType<typeof getGroupedDifferences>> = yield select(
    getGroupedDifferences(focusedGroupId || differenceId),
  );

  const previousGroupedDifferences: ReturnType<ReturnType<typeof getGroupedDifferences>> = yield select(
    getGroupedDifferences(yield select(getPreviousDifferenceId)),
  );
  const currentGroup: false | Group = yield select(getGroupById(focusedGroupId));
  const inspectionId: string = yield select(getInspectionId);
  const snackMessage: GVSnackMessage = yield select(getSnackMessage);

  if (type !== DifferenceTypeNames.Deletion && snackMessage && snackMessage.type === 'info') {
    yield put(app.actions.clearSnackMessageType('info'));
  }

  // if Its a group we need to patch the group to set viewed = true, and if its a difference, then patch the difference instead.
  if (currentGroup) {
    if (currentGroup.viewed === false) {
      yield put(patchGroup(inspectionId, currentGroup.id, { viewed: true }));
    }
  } else if (currentGroupedDifferences[0].viewed === false) {
    yield put(updateDifference(currentGroupedDifferences[0].id, { viewed: true }));
  }

  const docManager = PDFManagerFactory.getPDFDocManager();
  if (!currentGroupedDifferences.length || !docManager) return;

  // remove overlays from other pages before applying a new overlay
  if (previousGroupedDifferences.length) {
    docManager?.source?.removeHighContrasts();
    docManager?.target?.removeHighContrasts();
  }
  // create new high contrast background
  docManager?.applyHighContrast(currentGroupedDifferences);

  // remove flash animation if it exists
  docManager.resetAnimation();

  if (currentGroupedDifferences[0].type === DifferenceTypeNames.Graphics) {
    docManager.GraphicDifferenceFocused(currentGroupedDifferences);
  } else if (currentGroupedDifferences[0].type === DifferenceTypeNames.Barcode) {
    docManager.barcodeDifferenceFocused(currentGroupedDifferences);
  }

  // if live text is enabled when the difference is selected, we must redraw the text highlight after the high contrast annotation
  // for graphics differences this is handled in GraphicZone.animateGraphicDifference()
  const showLiveText: boolean = yield select(getShowLiveText);
  if (showLiveText && currentGroupedDifferences[0].type !== DifferenceTypeNames.Graphics) {
    PDFTronManager.redrawLiveTextHighlights();
  }
}

function* handleDeselectDifference() {
  const groupedDifferences: Difference[] = yield select(getGroupedDifferences(yield select(getPreviousDifferenceId)));
  // Deselect difference
  PDFManagerFactory.getPDFDocManager()?.deselectDifference(groupedDifferences);
  PDFManagerFactory.getPDFDocManager()?.target?.removeHighContrasts();
  PDFManagerFactory.getPDFDocManager()?.source?.removeHighContrasts();
  useZoomStateStore.getState().setZoomChanging();

  // Prevent firing on refresh or else index 0 of empty array throws error
  // Is used to show all hidden annotations from selecting a barcode annotation
  if (groupedDifferences.length > 0 && groupedDifferences[0].type === DifferenceTypeNames.Barcode) {
    PDFManagerFactory.getPDFDocManager()?.onDeselectBarcodeDifference();
  }
}

function* handleResetInspection() {
  yield put(files.actions.resetStore());
  yield put(inspection.actions.resetStore());
  yield put(app.actions.resetStore());
  yield put(report.actions.resetStore());
  yield put(
    resetRequests([
      fetchInspection.toString(),
      fetchDifferences.toString(),
      fetchGroups.toString(),
      'request/fetchFile',
    ]),
  );
}

function* handleAddFiles(action: any): Generator<any, void, any> {
  if (!action.payload.isNewFile) {
    // if we are fetching a file that is already part of the inspection, we dont need to update inspection data
    return;
  }

  yield trackTrackerEvent({
    name: 'file-downloaded',
    data: { fileName: action.payload.fileState.fileName, type: action.payload.documentType },
  });

  const creatingInspection: boolean = yield select(getUpdatingInspection);
  // if the inspection is being created we wait until it finishes
  if (creatingInspection) {
    yield take(inspection.actions.setInspectionData.type);
  }

  yield put(app.actions.setUpdatingInspection(true));

  const id: string = yield select(getInspectionId);
  if (id) {
    const updateInspectionData: UpdateInspectionData = yield select(getUpdateInspectionData);
    yield put(updateInspectionFiles(id, updateInspectionData));
  } else {
    const isSingleFile = yield select(getIsSingleFile);
    const filesId = yield select(getFilesIds);
    const filesLoaded = isSingleFile || (!isSingleFile && filesId.masterFileId && filesId.sampleFileId);
    if (filesLoaded) {
      const createInspectiondata: CreateInspectionData = yield select(getCreateInspectionData);
      yield put(createInspection(createInspectiondata));
    } else {
      yield put(app.actions.setUpdatingInspection(false));
    }
  }
}

function* handleResetDocumentState() {
  const creatingInspection: boolean = yield select(getUpdatingInspection);
  // if the inspection is being created we wait until it finishes
  if (creatingInspection) {
    yield take(inspection.actions.setInspectionData.type);
  }

  const id: string = yield select(getInspectionId);
  if (id) {
    yield put(report.actions.resetStore());

    yield put(app.actions.setUpdatingInspection(true));
    const updateInspectionData: UpdateInspectionData = yield select(getUpdateInspectionData);
    yield put(updateInspectionFiles(id, updateInspectionData));
  }
}

function* handleSelectUnconfirmedZones() {
  const zoneList: SelectedText[] = yield select(getZoneSelectedTexts);
  const unselectedAnnotationId = zoneList.find((zone) => !zone.selected)?.annotationId;
  const sourceManager = PDFTronAnnotationManager.getInstance(DocumentTypes.source);
  if (sourceManager && unselectedAnnotationId) {
    const unselectedAnnotation = sourceManager.getAnnotationById(unselectedAnnotationId);
    sourceManager.selectAnnotation(unselectedAnnotation);
  }
}

const addHoverEventListenersToSourceAndTarget = () => {
  const sourceWebViewer = PDFManagerFactory.getViewer(DocumentTypes.source);
  const targetWebViewer = PDFManagerFactory.getViewer(DocumentTypes.target);
  const iframeWindows = [];
  iframeWindows.push(sourceWebViewer?.getIframeWindow());
  iframeWindows.push(targetWebViewer?.getIframeWindow());
  iframeWindows.forEach((iframeWindow) => {
    iframeWindow?.addEventListener('mouseover', () => {
      iframeWindow.focus();
    });
  });
};

function* handleLoadingState() {
  const hasloaded: boolean = yield select(getDocumentsLoaded);
  if (hasloaded) {
    addHoverEventListenersToSourceAndTarget();
    // update whether the dimensions of files are equal for graphic zones
    PDFManagerFactory.getPDFDocManager()?.updateAreFileDimensionsEqual();

    const uploadedFile: { source: InspectFileState; target: InspectFileState } = yield select(getUploadedFilesSelector);
    const sourceFileName = uploadedFile.source.originalName;
    const newFileName = uploadedFile.target.originalName;
    const differentFileType = tableEnhancementFileCheck(sourceFileName, newFileName);
    yield put(inspection.actions.setTableEnhancementEnabledState(differentFileType));
  } else {
    yield put(inspection.actions.setTableEnhancementEnabledState(false));
  }
}

function* handleShowPanToolToast() {
  const toastStatuses: ToastStatuses = yield select(getToastStatuses);
  const userPrefs: UserPreferences = yield select(getUserPreferences) as UserPreferences;

  if (!toastStatuses.hasPanToolToastShown && !userPrefs?.disablePanToolToast) {
    useDocumentToastStateStore.getState().setDocumentToast(DocumentTypes.source, toastEvents.panToolToast);
    yield put(app.actions.panToolToastHasShown());
  }
}

function* handleNewPolygonPoints(
  action: PayloadAction<{
    documentType: DocumentTypes;
    points: [number, number][][][] | '';
    callSaveInspection?: boolean;
  }>,
) {
  const inspectionId: string = yield select(getInspectionId);
  const { documentType, points, callSaveInspection = true } = action.payload;

  if (typeof points === 'string') {
    const annotationManager = PDFTronAnnotationManager.getInstance(documentType);
    if (!annotationManager) return;
    annotationManager.deleteAnnotations(
      annotationManager
        .getAnnotationsList()
        .filter((annot: Core.Annotations.Annotation) => annot.Subject === 'Polygon'),
      { imported: false, force: true },
    );
  } else {
    const manager = PDFManagerFactory.getPDFDocManager();
    if (!manager) return;
    manager.drawPolygons(documentType, points);
  }

  if (callSaveInspection && inspectionId) {
    yield call(saveInspection);
  }
}

export default function* rootSaga() {
  // This actions will trigger
  const autoSaveActions = [
    inspection.actions.setAnnotations.type,
    inspection.actions.setPageRange.type,
    inspection.actions.setZoneSelected.type,
    inspection.actions.setInspectionSettings.type,
    inspection.actions.setSelectedCustomDictionaryIDs.type,
    inspection.actions.setLayers.type,
    inspection.actions.setSeparations.type,
    inspection.actions.setGraphicsOptions.type,
    inspection.actions.setPolygons.type,
    inspection.actions.toggleGraphics.type,
    inspection.actions.deleteOutdatedGraphicZones.type,
  ];

  // @todo move to pdftron folder
  yield all([
    takeEvery(inspection.actions.excludePage.type, function ({ payload }: ExcludePagePayload) {
      const { pageNumber, documentType } = payload;
      const docManager = PDFManagerFactory.getPDFDocManager();
      if (docManager) {
        const instance = docManager.getInstance(documentType);
        const otherInstance = docManager.getOtherInstance(instance);

        if (instance && otherInstance) {
          // Get the list of annotations that have to be removed because a page was excluded
          const annotationsToExclude = instance.getAnnotationList(
            (annot: Core.Annotations.Annotation) => annot.PageNumber === pageNumber,
          );
          const excludedCrossoutNumbers: number[] = annotationsToExclude
            .filter((annot) => annot.getCustomData(AnnotationCustomData.strikeoutAnnotation) === 'true')
            .map((crossoutAnnot) => parseInt(crossoutAnnot.getCustomData(AnnotationCustomData.zoneNumber)));

          // Find if there is any matching annotation on the other document. If that's the case, those have to be deleted as well.
          // This only happens for Zones (Marquee, Graphic and Text zones), because they always come in pairs (source and target annots are linked together)
          const otherInstanceAnnotations = otherInstance.getAnnotationList();
          annotationsToExclude.forEach((excludedAnnotation) => {
            if (zoneTypeByToolName[excludedAnnotation.ToolName] !== undefined) {
              // only for zone annotations
              let excludedAnnotationId = excludedAnnotation.Id;
              if (documentType === DocumentTypes.target) {
                // target zone annotations have some extra suffixes in the ID, we just need the common part
                excludedAnnotationId = excludedAnnotation.Id.split('_')[0];
              }
              const matchingAnnotations = otherInstanceAnnotations.filter((otherInstanceAnnotation) =>
                otherInstanceAnnotation.Id.includes(excludedAnnotationId),
              );
              if (matchingAnnotations.length) {
                // delete annotations that shared the same Id
                otherInstance.annotationManager.deleteAnnotations(matchingAnnotations, {
                  imported: false,
                  force: true,
                });
              }
            }
          });

          // delete all annotations from the page we excluded
          instance.annotationManager.deleteAnnotations(annotationsToExclude, {
            imported: false,
            force: true,
          });

          // update redux annotations
          docManager.updateInputAnnotations();

          // shake sequence ids for any zone that could be left in the documents
          shakeDocumentsZones();

          // shake sequence ids for crossouts
          Crossout.shakeCrossouts(excludedCrossoutNumbers);
        }
      }
    }),
    takeEvery(success(addUser.type), function* ({ payload }: any) {
      yield trackTrackerEvent({
        name: 'user-added',
        data: {
          targetUserId: payload.data.user_id,
          userEmail: payload.data.email,
          orgId: payload.data.app_metadata?.org_id,
        },
      });
    }),
    takeEvery(success(updateUser.type), function* ({ meta, payload }: any) {
      yield trackTrackerEvent({
        name: 'user-updated',
        data: {
          targetUserId: payload.userId,
          oldRole: meta.userInfo.oldRole.name,
          newRole: meta.userInfo.newRole.name,
          oldFirstName: meta.userInfo.initialValues.firstName,
          newFirstName: payload.userInfo.firstName,
          oldLastName: meta.userInfo.initialValues.lastName,
          newLastName: payload.userInfo.lastName,
        },
      });
    }),
    takeEvery(success(updateUserData.type), function* ({ meta }: any) {
      // self changing names and password are from the same action, but we don't track users' password
      if (meta.userInfo.values.firstName || meta.userInfo.values.lastName) {
        yield trackTrackerEvent({
          name: 'user-self-updated',
          data: {
            firstNameOldValue: meta.userInfo.initialValues.firstName,
            lastNameOldValue: meta.userInfo.initialValues.lastName,
            firstNameNewValue: meta.userInfo.values.firstName,
            lastNameNewValue: meta.userInfo.values.lastName,
          },
        });
      } else if (meta.userInfo.values.currentPassword && meta.userInfo.values.newPassword) {
        yield trackTrackerEvent({
          name: 'user-change-password',
          data: {},
        });
      }
    }),
    takeEvery(success(fetchFile.type), function* ({ payload, meta }: FetchFilePayload) {
      yield trackTrackerEvent({
        name: 'file-downloaded',
        data: { fileName: payload?.data?.filename, type: meta?.document },
      });
    }),
    takeEvery(success(startFileCreation.type), function* ({ payload, meta }: FileUploadResult) {
      yield trackTrackerEvent({
        name: 'file-uploaded',
        data: {
          fileName: payload?.data?.filename !== '' ? payload?.data?.filename : payload?.data?.originalFilename,
          type: meta?.requestAction?.meta?.document,
        },
      });
    }),
    takeEvery(app.actions.traceLoginAttempts.type, function* ({ payload }: PayloadAction<{ username: string }>) {
      yield trackTrackerEvent({ name: 'user-login-attempted', data: { username: payload.username } });
    }),
    takeEvery(success(fetchDifferences.toString()), function* ({ meta }: any) {
      // we take from fetchDifferences to track inspection results because we get differences after but they are needed in the track event
      if (!meta?.shouldTrackInspection) return;

      const differences: ReturnType<typeof getDifferences> = yield select(getDifferences);
      const differencesQty = differences.length;
      const differencesType: number = yield select(getSegmentDisplayedDifferenceCounts);
      const smartDiscardQty: number = yield select(getSegmentSmartDiscardedDifferencesCount);
      const projectName: string = yield select(getName);
      const uploadedFiles: { source: InspectFileState; target: InspectFileState } = yield select(
        getUploadedFilesSelector,
      );
      const excludedRange: { source: number[]; target: number[] } = yield select(getExcludedPage);
      const annotations: { source: InputAnnotation[]; target: InputAnnotation[] } = yield select(getAnnotations);
      const spellingTrackingData: string = yield select(getSpellingTrackingData);
      const inspectionSettings: InspectionSettings = yield select(getInspectionSettings);
      const { GraphicCreateTool }: ReturnType<typeof getNumberOfAnnotations> = yield select(getNumberOfAnnotations);

      yield trackTrackerEvent({
        name: 'document-inspected',
        data: {
          differencesQty,
          differencesType,
          projectName,
          settings: inspectionSettings,
          masterFileId: uploadedFiles?.source?.fileId,
          masterFileName: uploadedFiles?.source?.fileName,
          sampleFileId: uploadedFiles?.target?.fileId,
          sampleFileName: uploadedFiles?.target?.fileName,
          inclusions: [{ excludedRange, annotations }],
          spelling: spellingTrackingData,
          matchedGraphics: GraphicCreateTool.source.matchedGraphic,
          fullPageGraphics: `${inspectionSettings.fullPageGraphics}`,
          shiftedGraphics: GraphicCreateTool.source.shifted,
          scaledGraphics: GraphicCreateTool.source.scaled,
        },
      });
      yield trackTrackerEvent({
        name: 'Differences-per-inspection',
        data: {
          differencesQty,
          smartDiscardQty,
          differencesType,
        },
      });
    }),
    takeEvery(startInspection.type, handleStartInspection),
    takeEvery(inspection.actions.setLoadingState.type, handleLoadingState),
    // Debounce for 1 second before updating inspections as setAnnotations can
    // occur multiple times in a short amount of time (e.g. excluded pages)
    debounce<string[], () => void>(1000, autoSaveActions, autoSaveInspection),
    takeEvery(success(pollInspectionStatus.toString()), function* () {
      yield put(app.actions.setResetIdleTimer(true));
    }),
    takeEvery(success(fetchFileList.type), handleFilesList),
    // we need this one to set the autosaving flag without the delay of the debounce
    takeEvery([...autoSaveActions], handleSetAutosavingFlag),
    takeEvery(inspection.actions.setName.type, autoSaveName),
    takeEvery(selectUnconfirmedZones.type, handleSelectUnconfirmedZones),
    takeEvery(inspection.actions.focusDifference.type, handleFocusDifference),
    takeEvery(inspection.actions.setFlashMode.type, handleFlashMode),
    takeEvery(app.actions.setShowDifferenceBoxes.type, handleDifferenceBoxVisibility),
    takeEvery(app.actions.setShowOverlay.type, handleOverlayVisibility),
    takeEvery(inspection.actions.unfocusDifference.type, handleDeselectDifference),
    takeEvery(resetInspection.type, handleResetInspection),
    takeEvery(files.actions.setFetchedFile.type, handleAddFiles),
    takeEvery(files.actions.setFileFromLibrary.type, handleAddFiles),
    takeEvery(app.actions.setFocusedMatchIndex.type, handleFocusMatch),
    takeEvery(inspection.actions.setDifferenceViewOptions.type, handleSetDifferenceViewOptions),
    takeEvery(inspection.actions.resetDocumentState.type, handleResetDocumentState),
    // Debounce for 1 second before updating inspections as setAnnotations can
    // occur multiple times in a short amount of time (e.g. excluded pages)
    takeEvery(inspection.actions.increaseTextZoneId, handleShowPanToolToast),
    takeEvery(inspection.actions.increaseMarqueeZoneId, handleShowPanToolToast),
    takeEvery(inspection.actions.increaseGraphicZoneId, handleShowPanToolToast),
    takeEvery(inspection.actions.increaseCrossoutZoneId, handleShowPanToolToast),
    takeEvery(inspection.actions.setPolygons.type, handleNewPolygonPoints),
    takeEvery(inspection.actions.setSingleFileInspection.type, handleSingleFileInspection),
    ...reportSagas,
    ...docManagerSagas,
    ...auth0Sagas,
    ...appSagas,
    ...differencesSagas,
  ]);
}
