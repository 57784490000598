import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { Typography } from '@mui/material';
import DifferenceDescription from '../DifferenceDescription/DifferenceDescription';
import { DifferenceProps } from '../DifferenceElementView';

const maxWidth = 126; // DifferenceDescription component width

const getLimitedText = (text: string = '', maxWidth: number, font: string = '16px Arial') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
  }

  const words = text.split(',').map((word) => word.trim());
  let result = '';
  let totalWidth = 0;

  for (let i = 0; i < words.length; i++) {
    const currentWord = words[i];
    const prefix = i === 0 ? '' : ', ';
    const wordToAdd = prefix + currentWord;

    const wordWidth = context?.measureText(wordToAdd).width ?? 0;

    if (totalWidth + wordWidth <= maxWidth) {
      result += wordToAdd;
      totalWidth += wordWidth;
    } else {
      break;
    }
  }
  return result;
};

const SpellingDifference = ({ difference, isSelected }: DifferenceProps) => {
  const text = difference?.target?.text || '';
  const limitedTargetText = getLimitedText(text, maxWidth);

  const targetDescription = (
    <GVTooltip title={difference.target.text} disableHoverListener={!isSelected}>
      <Typography noWrap variant="subtitle1">
        {limitedTargetText}
      </Typography>
    </GVTooltip>
  );
  return (
    <DifferenceDescription
      difference={difference}
      targetDescription={targetDescription}
      sourcePageNumberText={`identified new (p${difference.source.location.pageNumber})`}
      targetPageNumberText="suggestions"
      showExtra={false} // we dont use extra for spelling
    />
  );
};

export default SpellingDifference;
