import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DifferenceProps } from '../DifferenceElementView';
import DifferenceDescription from '../DifferenceDescription/DifferenceDescription';
import GraphicsDifferenceIcon from 'components/icons/GraphicsDifferenceIcon/GraphicsDifferenceIcon';
import { useSelector } from 'react-redux';
import { getThumbnailsImagesPath } from 'store';
import { AspectRatio, Box } from '@mui/joy';
import { DocumentTypes } from 'types';

const useStyles = makeStyles()(() => ({
  thumbnail: {
    borderRadius: '4px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    contentVisibility: 'auto',
  },
}));

// Cache for thumbnail ready states
const imageReadyCache: Record<string, boolean> = {};

const GraphicDifference = ({ difference }: DifferenceProps) => {
  const { classes } = useStyles();
  const sourcedifferenceThumbnailBasePath = useSelector(getThumbnailsImagesPath('master'));
  const targetdifferenceThumbnailBasePath = useSelector(getThumbnailsImagesPath('sample'));
  const differenceNumber = difference.differenceIndex;

  const sourceThumbnailPath = `${sourcedifferenceThumbnailBasePath}${differenceNumber}.png`;
  const targetThumbnailPath = `${targetdifferenceThumbnailBasePath}${differenceNumber}.png`;

  const [sourceReady, setSourceReady] = useState(!!imageReadyCache[sourceThumbnailPath]);
  const [targetReady, setTargetReady] = useState(!!imageReadyCache[targetThumbnailPath]);
  const [sourceRetryCount, setSourceRetryCount] = useState(0);
  const [targetRetryCount, setTargetRetryCount] = useState(0);

  const initialDelay = 2000;
  const retryDelay = 500;
  const maxRetries = 1000;

  const loadImageWithRetry = (
    thumbnailPath: string,
    setReady: React.Dispatch<React.SetStateAction<boolean>>,
    retryCount: number,
    setRetryCount: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    const img = new Image();
    img.src = thumbnailPath;

    img.onload = () => {
      setReady(true);
      imageReadyCache[thumbnailPath] = true;
    };

    img.onerror = () => {
      const delay = retryCount === 0 ? initialDelay : retryDelay;
      if (retryCount < maxRetries) {
        setTimeout(() => {
          setRetryCount(retryCount + 1);
          loadImageWithRetry(thumbnailPath, setReady, retryCount + 1, setRetryCount);
        }, delay);
      }
    };
  };

  useEffect(() => {
    if (!imageReadyCache[sourceThumbnailPath]) {
      loadImageWithRetry(sourceThumbnailPath, setSourceReady, sourceRetryCount, setSourceRetryCount);
    }
    if (!imageReadyCache[targetThumbnailPath]) {
      loadImageWithRetry(targetThumbnailPath, setTargetReady, targetRetryCount, setTargetRetryCount);
    }
  }, [sourceThumbnailPath, targetThumbnailPath]);

  const Thumbnail = ({
    thumbnailPath,
    isReady,
    documentType,
  }: {
    thumbnailPath: string;
    isReady: boolean;
    documentType: DocumentTypes;
  }) => (
    <Grid container justifyContent="center" alignItems="center">
      {isReady ? (
        <Box id="force_dark_mode" sx={{ width: 300, p: 1, borderRadius: '4px', paddingLeft: 0 }}>
          <AspectRatio objectFit="cover" style={{ borderRadius: '4px' }}>
            <img
              id={`${documentType}-thumbnail-${differenceNumber}`}
              src={thumbnailPath}
              alt={`${documentType.charAt(0).toUpperCase() + documentType.slice(1)} Difference # ${differenceNumber}`}
              className={classes.thumbnail}
            />
          </AspectRatio>
        </Box>
      ) : (
        <GraphicsDifferenceIcon />
      )}
    </Grid>
  );

  const sourceThumbnail = (
    <Thumbnail thumbnailPath={sourceThumbnailPath} isReady={sourceReady} documentType={DocumentTypes.source} />
  );

  const targetThumbnail = (
    <Thumbnail thumbnailPath={targetThumbnailPath} isReady={targetReady} documentType={DocumentTypes.target} />
  );

  return (
    <DifferenceDescription
      difference={difference}
      sourceDescription={sourceThumbnail}
      targetDescription={targetThumbnail}
    />
  );
};

export default GraphicDifference;
