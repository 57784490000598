import {
  InspectionState,
  DocumentStates,
  DocumentData,
  DocumentTextProcessing,
  PageRangeOption,
  DocumentTypes,
  ShowLibrary,
  DocumentToastState,
  PDFTRON_DEFAULT_TOOL,
  SortDifferencesOption,
} from 'types';

const initialDocumentState: DocumentData = {
  state: DocumentStates.INITIAL,
  pageRange: [],
  annotations: [],
  liveText: false,
  documentTextProcessing: DocumentTextProcessing.INITIAL,
  zoneSelectedTexts: [],
  pages: [],
  totalPageCount: 0,
  currentPage: 1,
  layers: undefined,
  separations: undefined,
};

const showLibrary: ShowLibrary = {
  [DocumentTypes.source]: false,
  [DocumentTypes.target]: false,
};

const initialInspectionState: InspectionState = {
  source: initialDocumentState,
  target: initialDocumentState,
  report: initialDocumentState,
  selectedTool: PDFTRON_DEFAULT_TOOL,
  differenceViewOptions: {
    sortBy: SortDifferencesOption.readingOrder,
    filters: {},
    selectedModule: 'All',
    hideDisplayPanels: { [DocumentTypes.source]: false, [DocumentTypes.target]: false },
  },
  differencesLoaded: false,
  focusedDifferenceId: '-1',
  previousDifferenceId: '-1',
  syncScrolling: false,
  noResultAnnotationId: '',
  invalidScaledGraphicZoneRatio: false,
  unconfirmTextZoneError: false,
  unconfirmedGraphicZoneError: false,
  unconfirmedMarqueeZoneError: false,
  pendingCropZone: { documentType: null, pendingCropId: '' },
  pendingShapeRequest: null,
  manualSelectedZoneId: 0,
  manualSelectedAnnotationId: '',
  loadingInspection: false,
  name: '',
  inspectionId: '',
  inspectionStatus: '',
  lastJobId: null,
  lastJobInput: null,
  ctfJobs: [],
  showDiscarded: false,
  selectingZone: false,
  openPanel: false,
  pageRangeOption: PageRangeOption.ALL,
  showLibrary,
  internalAnnotationsVisibility: true,
  internalAnnotationsVisibilityDefault: true,
  tableEnhancementEnabledState: false,
  nextTextZoneId: 1,
  nextGraphicZoneId: 1,
  nextCropZoneId: 1,
  nextMarqueeZoneId: 1,
  nextCrossoutZoneId: 1,
  settings: {
    tableEnhancement: false,
    dictionaryName: undefined,
    fullPageGraphics: false,
    barcode: false,
    singleFile: false,
  },
  autoMatchGraphic: {
    autoMatchFail: 0,
    autoMatchPass: 0,
  },
  customDictionaryIDs: [],
  updatedAt: '',
  differencePanelOpen: false,
  changeGridDirection: 'ltr',
  shiftedGraphicRefId: '',
  persistedDifferenceComments: {},
  isFilesLoaded: false,
  searchingForGraphicZone: false,
  reloadingDifferences: false,
  isBarcode: false,
  brailleLanguage: '',
  unmatchedPages: [],
  isResultsPanelHidden: false,
  flashMode: true,
  lastJobMatches: [],
  lastJobImages: undefined,
  polygons: {
    [DocumentTypes.source]: { points: '', key: '', type: '', layerOrSeparationName: '' },
    [DocumentTypes.target]: { points: '', key: '', type: '', layerOrSeparationName: '' },
  },
};

export { initialDocumentState, initialInspectionState };
