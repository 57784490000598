import React, { ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import { Button, ButtonProps, Grid } from '@mui/material';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { makeStyles } from 'tss-react/mui';

interface GVControlButtonProps {
  id: string;
  action: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<any>) => void;
  tooltipTitle?: string;
  children: ReactNode;
}

const useStyles = makeStyles()((theme: Theme) => ({
  buttons: {
    width: theme.spacing(4),
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4),
  },
  buttonContainer: {
    marginRight: theme.spacing(1),
  },
}));

const GVButton = (props: GVControlButtonProps & ButtonProps) => {
  const { id, action, tooltipTitle, ...otherProps } = props;
  const { classes } = useStyles();
  return (
    <Grid item className={classes.buttonContainer}>
      <GVTooltip title={`${props.tooltipTitle}`} placement="top">
        <Button
          className={classes.buttons}
          id={`${props.id}`}
          color="primary"
          size="small"
          onClick={props.action}
          variant="outlined"
          {...otherProps}
        >
          {props.children}
        </Button>
      </GVTooltip>
    </Grid>
  );
};

export default GVButton;
