import { useState, useEffect } from 'react';
import { Backdrop, Theme, Typography, Button, Grid, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getStyleVariables, opacities } from 'styles/vars';
import { GVIcon } from 'components/icons';
import {
  getInspectionStarted,
  getDifferencesLoadedSelector,
  getInspectionIdle,
  getSearchingForGraphic,
  inspection,
  getLoadingDifferences,
  getProcessingFlattenedText,
  files,
  getInspectionId,
  getStartingInspection,
  getInspectionFailed,
  getShapesJobStatus,
} from 'store';
import cx from 'classnames';
import { DocumentTypes, FileStatus } from 'types';
import { cancelInspection } from 'store/request/inspections/actions';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
      backgroundColor: styleVariables.global.backdropColor,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      marginLeft: theme.spacing(1),
    },
    icon: {
      width: theme.spacing(3.5),
      height: 'auto',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    },
    red: {
      color: theme.palette.secondary.main,
      marginRight: theme.spacing(1),
    },
    progressContainer: {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: '50%',
    },
    white: {
      color: theme.palette.text.primary,
    },
    disabled: {
      opacity: opacities.disabled,
    },
  };
});

const ResultsLoadingBackdrop = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const searching = useSelector(getSearchingForGraphic);
  const reloadingDifferences = useSelector(getLoadingDifferences);
  const startingInspection = useSelector(getStartingInspection);
  const inspectionStarted = useSelector(getInspectionStarted);
  const inspectionIdle = useSelector(getInspectionIdle);
  const inspectionFailed = useSelector(getInspectionFailed);
  const differenceLoaded = useSelector(getDifferencesLoadedSelector);
  const ocrStatus = useSelector(getProcessingFlattenedText);
  const fetchingShape = useSelector(getShapesJobStatus) === FileStatus.inprogress;
  const inspectionId = useSelector(getInspectionId);
  const processingFlattenedText = Object.values(ocrStatus).includes(true);
  const [cancellingDisabled, setCancellingDisabled] = useState<boolean>(false);
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [displayText, setDisplayText] = useState<string>('');
  const [buttonText, setButtonText] = useState<string>('');

  useEffect(() => {
    if (!startingInspection || !inspectionStarted || !searching || !reloadingDifferences || !processingFlattenedText) {
      setCancelling(false);
      setCancellingDisabled(false);
    }
  }, [startingInspection, inspectionStarted, searching, reloadingDifferences]);

  useEffect(() => {
    if (
      startingInspection ||
      inspectionStarted ||
      searching ||
      reloadingDifferences ||
      processingFlattenedText ||
      fetchingShape
    ) {
      setOpen(true);
    } else if (inspectionIdle || differenceLoaded || inspectionFailed) {
      // check if inspection got cancelled or if we got the results
      setCancelling(false);
      setOpen(false);
    }
    if (searching) {
      setDisplayText('Verify is locating the matching image...');
      setButtonText('Search');
    } else if (startingInspection || inspectionStarted) {
      setDisplayText('Inspecting your files...');
      setButtonText('Inspection');
      setCancellingDisabled(!inspectionStarted);
    } else if (reloadingDifferences) {
      setDisplayText('Generating inspection results');
      setButtonText('Process');
    } else if (processingFlattenedText) {
      setDisplayText('Converting your file to live text...');
      setButtonText('Process');
    } else if (fetchingShape) {
      setDisplayText('Verify is locating and setting the shape...');
      setButtonText('Process');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inspectionStarted,
    searching,
    differenceLoaded,
    inspectionIdle,
    reloadingDifferences,
    processingFlattenedText,
    startingInspection,
    fetchingShape,
  ]);

  const handleCancelLoading = () => {
    setCancelling(true);
    if (searching) {
      dispatch(inspection.actions.setSearchingForZone(false));
    } else if (reloadingDifferences) {
      dispatch(inspection.actions.setLoadingDifferences(false));
    } else if (processingFlattenedText) {
      dispatch(
        files.actions.setOCRStatus({
          documentType: ocrStatus.source ? DocumentTypes.source : DocumentTypes.target,
          status: '',
        }),
      );
    } else if (fetchingShape) {
      dispatch(files.actions.setShapesJobStatus(FileStatus.cancelled));
    } else {
      dispatch(cancelInspection(inspectionId));
    }
  };

  return (
    <Backdrop
      open={open}
      className={cx(classes.backdrop, { backdropClosed: !open })}
      data-testid="inspection_loading_backdrop"
    >
      <Grid container direction="column">
        <Grid item className={classes.titleContainer}>
          <GVIcon className={classes.icon} />
          <Typography className={classes.title} variant="h4">
            {displayText}
          </Typography>
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <Button onClick={handleCancelLoading} disabled={cancellingDisabled || cancelling}>
            <span
              className={cx(classes.red, {
                [classes.disabled]: cancellingDisabled,
              })}
            >
              {cancelling ? 'Cancelling' : 'Cancel'}
            </span>
            <span
              className={cx(classes.white, {
                [classes.disabled]: cancellingDisabled,
              })}
            >
              {buttonText}
              {cancelling ? ' ...' : ''}
            </span>
          </Button>
        </Grid>
        <Grid item className={classes.progressContainer}>
          <CircularProgress />
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default ResultsLoadingBackdrop;
