import { UpdateInspectionData, InspectionStatuses, DocumentTypes, InspectionSettings, CTFModuleTypes } from 'types';
import { createAction as createSmartAction } from 'redux-smart-actions';
import { RequestAction, QueryState, stopPolling } from '@redux-requests/core';
import { inspection, app, files, getFocusedDifference, getUnmatchedPages } from 'store';
import { AxiosError } from 'axios';
import { fetchFile } from 'store/requests';
import { PDFManagerFactory } from 'pdftron';
import store, { requestsStore } from 'store/store';
import {
  ApiUpdateInspection,
  ApiFetchInspectionsList,
  ApiStartInspection,
  ApiCreateInspection,
  ApiFetchInspection,
  ApiPollInspectionStatus,
} from './types';
import { fetchDifferences } from '../differences/actions';
import { fetchGroups } from '../difference-group/actions';
import setInspectionMarkups from './setInspectionMarkups';

export const fetchInspectionsList = createSmartAction<
  RequestAction<ApiFetchInspectionsList, ApiFetchInspectionsList | undefined>
>('inspections/fetchInspectionsList', () => ({
  request: {
    url: `/inspections`,
    method: 'GET',
  },
}));

type UpdateLandmarkSettings = [
  InspectionSettings,
  string, // inspection id
];
export const updateLandmarkSettings = createSmartAction<RequestAction<ApiUpdateInspection>, UpdateLandmarkSettings>(
  'inspections/updateLandmarkSettings',
  (newSettings, id) => ({
    request: {
      url: `/inspections/${id}`,
      method: 'PATCH',
      data: { settings: newSettings },
    },
    meta: {
      asMutation: true,
      errorMessage: 'There was an error updating state of differences. Please try again.',
      onSuccess: (response: QueryState<ApiUpdateInspection>, _action, store) => {
        store.dispatch(inspection.actions.setUpdatedAt(response.data.updatedAt));
        return response;
      },
    },
  }),
);

export const fetchInspection = createSmartAction<
  RequestAction<ApiFetchInspection>,
  [
    string, // inspection id
    'job' | 'session', // is inspection complete
  ]
>('inspections/fetchInspection', (inspectionId, jobOrSession) => {
  return {
    request: {
      url: `/inspections/${inspectionId}`,
      method: 'GET',
      withCredentials: true,
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
    meta: {
      errorMessage: 'There was an error. Please try again.',
      onRequest: (request, _requestAction, store) => {
        store.dispatch(inspection.actions.setDifferencesLoaded(false));
        return request;
      },
      onSuccess: (response: QueryState<ApiFetchInspection>, _action, store) => {
        const isBarcode = response.data.lastJob?.metadata?.isBarcode;

        /*
      we couple inspection SESSION and inspection JOB (results) together but we need the request data for lastjob data inside one action, so we separate the logic for two cases
        we only call this action in two instances:
        1. we fetch the new inspection JOB, where we are updating the results in which the files are already loaded. note: we are not concerned with inspection session data
        2. we fetch the inspection SESSION, where we reload the session in which the files must be loaded. note: a session may not have inspection results
       */
        if (jobOrSession === 'job') {
          // case #1: documents are already loaded, and we are fetching the new inspection data after running a new inspection
          const { status, lastJobId, lastJob, id, filterOptions, sortOptions } = response.data;
          const lastGraphicsJob = lastJob?.ctfJobs.find((job) => job.type === CTFModuleTypes.graphics)?.rawResults;
          const lastJobMatches = lastGraphicsJob?.matches;
          const lastJobImages = lastGraphicsJob?.images;

          store.dispatch(
            inspection.actions.setInspectionData({
              inspectionStatus: status,
              lastJobId,
              lastJobInput: lastJob?.input,
              ctfJobs: lastJob?.ctfJobs.map((job) => job.type),
              lastJobMatches,
              lastJobImages,
              settings: response.data?.settings,
            }),
          );

          store.dispatch(
            inspection.actions.setDifferenceViewOptions({
              sortBy: sortOptions.sortBy,
              filters: filterOptions,
            }),
          );

          store.dispatch(fetchDifferences(id, true));
          store.dispatch(fetchGroups(id));
          PDFManagerFactory.getPDFDocManager()?.generateInspectedAnnotations(DocumentTypes.source);
          PDFManagerFactory.getPDFDocManager()?.generateInspectedAnnotations(DocumentTypes.target);

          if (isBarcode) {
            store.dispatch(inspection.actions.setIsBarcode(isBarcode));
          }

          store.dispatch(app.actions.setStartingInspection(false));
        } else {
          // When this request is called to fetch the session, it means we are updating all the inspection data. Thus, we have to clean any existing state to avoid conflicts.
          const {
            id,
            name,
            input,
            status,
            masterFileId,
            sampleFileId,
            filterOptions,
            sortOptions,
            lastJobId,
            settings,
            updatedAt,
            lastJob,
            customDictionaryIDs,
          } = response.data;
          store.dispatch(inspection.actions.resetStore());

          store.dispatch(files.actions.setIsFetchingInspectionFiles(true));
          // We check for input to get layers settings. This must occur BEFORE we fetch the files so that we can refer to layers when the document is loaded
          // TODO we should try to have a one set for input here instead of three separate ones.
          input?.documents.forEach((inputDoc) => {
            if (inputDoc.layers) {
              store.dispatch(
                inspection.actions.setLayers({
                  documentType: inputDoc.type === 'master' ? DocumentTypes.source : DocumentTypes.target,
                  layers: inputDoc.layers,
                }),
              );
            }
            if (inputDoc.separations) {
              store.dispatch(
                inspection.actions.setSeparations({
                  documentType: inputDoc.type === 'master' ? DocumentTypes.source : DocumentTypes.target,
                  separations: inputDoc.separations,
                }),
              );
            }
            if (inputDoc.polygons) {
              const { points, type, key } = inputDoc.polygons;
              store.dispatch(
                inspection.actions.setPolygons({
                  points,
                  documentType: inputDoc.type === 'master' ? DocumentTypes.source : DocumentTypes.target,
                  type,
                  key,
                  callSaveInspection: false,
                }),
              );
            }
          });

          let isFilesLoaded = false;
          if (masterFileId) {
            store.dispatch(fetchFile(masterFileId, DocumentTypes.source, false));
            isFilesLoaded = true;
          }

          if (sampleFileId) {
            store.dispatch(fetchFile(sampleFileId, DocumentTypes.target, false));
            isFilesLoaded = true;
          }

          // if the inspection has already run we fetch the results
          if (lastJobId) {
            store.dispatch(fetchDifferences(id));
            store.dispatch(fetchGroups(id));
          }

          const lastGraphicsJob = lastJob?.ctfJobs.find((job) => job.type === CTFModuleTypes.graphics)?.rawResults;
          const lastJobMatches = lastGraphicsJob?.matches;
          const lastJobImages = lastGraphicsJob?.images;

          // Set inspection data in store
          store.dispatch(
            inspection.actions.setInspectionData({
              id,
              name,
              inspectionStatus: status,
              lastJobId,
              lastJobInput: lastJob?.input,
              settings,
              updatedAt,
              customDictionaryIDs,
              isFilesLoaded,
              ctfJobs: lastJob?.ctfJobs.map((job) => job.type),
              lastJobMatches,
              lastJobImages,
            }),
          );

          // @pdftron-refactor we should move this onSuccess of fetchInspectionResults as it could happen before the request is done, and that could cause errors.
          store.dispatch(
            inspection.actions.setDifferenceViewOptions({ filters: filterOptions, sortBy: sortOptions?.sortBy }),
          );

          if (input) {
            setInspectionMarkups(input);
          }

          if (isBarcode) {
            store.dispatch(inspection.actions.setIsBarcode(isBarcode));
          }

          if (status === InspectionStatuses.inprogress) {
            store.dispatch(pollInspectionStatus(id));
          }
        }

        if (response.data.lastJob?.metadata?.isFullPageGraphics) {
          // if full page graphics was run, we need to update what pages couldn't find a match
          const graphicsJob = response.data.lastJob?.ctfJobs.find((job) => job.type === CTFModuleTypes.graphics);
          if (graphicsJob) {
            const masterInput = response.data.input?.documents[0];
            if (!masterInput) return;
            const includedPageNumbers = masterInput.pages.filter((page) => page.included).map((page) => page.number);
            const pagesWithMasterGraphicZones = new Set(masterInput.graphicZones?.map((zone) => zone.pageNumber) || []);

            if (graphicsJob.error) {
              const unmatchedPages = includedPageNumbers.filter(
                // if graphics job had an error, we need to specify that the included pages without zones failed
                (pageNumber) => !pagesWithMasterGraphicZones.has(pageNumber),
              );
              store.dispatch(inspection.actions.setUnmatchedPages(unmatchedPages));
            } else {
              const pagesWithMatches = new Set(
                graphicsJob.rawResults.matches?.map((match) => match.matchDetails[0].masterLocation.pageNumber) || [],
              );

              const unmatchedPages = includedPageNumbers.filter(
                // we alert the user for every included page that looked for a match (included page without a zone) that it was unmatched
                (pageNumber) => !pagesWithMatches.has(pageNumber) && !pagesWithMasterGraphicZones.has(pageNumber),
              );
              store.dispatch(inspection.actions.setUnmatchedPages(unmatchedPages));
            }
          }
        } else {
          // full page graphics was not run, there are no unmatched pages
          store.dispatch(inspection.actions.setUnmatchedPages([]));
        }

        const unmatchedPages = getUnmatchedPages(store.getState());
        if (jobOrSession === 'job' && unmatchedPages.length > 0) {
          window.analytics.track('Graphics-failure', {
            inspectionID: inspectionId,
            'Failure-count': unmatchedPages.length,
            'Pages-failed': unmatchedPages,
          });
        }

        return response;
      },
    },
  };
});

export const pollInspectionStatus = createSmartAction<
  RequestAction<ApiFetchInspection>,
  [
    string, // inspection id
  ]
>('inspections/pollInspectionStatus', (inspectionId) => ({
  request: {
    url: `/inspections/status/${inspectionId}`,
    method: 'GET',
  },
  meta: {
    poll: 1,
    errorMessage: 'There was an error loading the results. Please try again',
    onSuccess: (response: QueryState<ApiPollInspectionStatus>, _action, store) => {
      const inspectionStatus = response.data;

      if (
        inspectionStatus === InspectionStatuses.completed ||
        inspectionStatus === InspectionStatuses.error ||
        inspectionStatus === InspectionStatuses.idle // we stop polling once we receive back that we have cancelled the inspection
      ) {
        store.dispatch(stopPolling([pollInspectionStatus.toString()]));
        store.dispatch(inspection.actions.setInspectionData({ inspectionStatus }));
      }

      if (inspectionStatus === InspectionStatuses.error) {
        store.dispatch(
          app.actions.setSnackMessage({
            message: 'There was an error loading the results. Please try again',
            type: 'error',
          }),
        );
        store.dispatch(app.actions.setStartingInspection(false));
      }

      if (inspectionStatus === InspectionStatuses.completed) {
        requestsStore.dispatchRequest(fetchInspection(inspectionId, 'job')); // fetch the inspection results once inspection has completed
      }

      return response;
    },
    onError: (error, _requestAction, store) => {
      store.dispatch(app.actions.setStartingInspection(false));
      return error;
    },
  },
}));

export const cancelInspection = createSmartAction<
  RequestAction<string>,
  [
    string, // inspection id
  ]
>('inspections/cancel', (inspectionId) => ({
  request: {
    url: `/inspections/${inspectionId}/cancel`,
    method: 'PATCH',
  },
  meta: {
    errorMessage: 'There was an error. Please try again.',
    onRequest: (request, _requestAction, store) => {
      store.dispatch(stopPolling([pollInspectionStatus.toString()]));
      store.dispatch(inspection.actions.setDifferencesLoaded(false));
      return request;
    },
    onSuccess: (response: QueryState<string>, _action) => {
      if (response.data === InspectionStatuses.finalizing) {
        store.dispatch(pollInspectionStatus(inspectionId));
      } else {
        store.dispatch(fetchInspection(inspectionId, 'job'));
      }
      return response;
    },
  },
}));

type StartInspectionPayload = [
  string, // inspection id
  {
    noLiveText: boolean;
  },
];
// TEMP: currently being named runInspection because this is called through a side effect of 'startInspection'
export const runInspection = createSmartAction<RequestAction<ApiStartInspection>, StartInspectionPayload>(
  'inspections/startInspection',
  (id, data) => ({
    payload: {
      request: {
        url: `/inspections/${id}/start`,
        method: 'POST',
        data,
      },
    },
    meta: {
      errorMessage: 'There was an error. Please try again.', // TODO: get correct message
      asMutation: true,
      onSuccess: (response: QueryState<ApiStartInspection>, _action, store) => {
        store.dispatch(inspection.actions.setInspectionData({ inspectionStatus: InspectionStatuses.queued }));
        store.dispatch(pollInspectionStatus(id));
        return response;
      },
      onError: (error: AxiosError, _action, store) => {
        store.dispatch(app.actions.setStartingInspection(false));
        throw error;
      },
    },
  }),
);

export interface CreateInspectionData {
  masterFileId?: string;
  sampleFileId?: string;
  name?: string;
  id?: string;
  settings?: InspectionSettings;
}
export const createInspection = createSmartAction<RequestAction<ApiCreateInspection>, [CreateInspectionData]>(
  'inspections/createInspection',
  (data) => ({
    request: {
      url: '/inspections',
      method: 'POST',
      data,
    },
    meta: {
      asMutation: true,
      errorMessage: 'There was an error creating the inspection. Please try again.',
      onSuccess: (response: QueryState<ApiCreateInspection>, _action, store) => {
        const { id, name, status, updatedAt, settings } = response.data;
        const oldSettings = store.getState().inspection.settings;
        store.dispatch(
          inspection.actions.setInspectionData({
            id,
            name,
            inspectionStatus: status,
            lastJobId: null,
            updatedAt,
            settings: { ...oldSettings, ...settings },
          }),
        );
        store.dispatch(app.actions.setAutosaving(false));
        store.dispatch(app.actions.setUpdatingInspection(false));
        return response;
      },
      onError: (error: AxiosError, _action, store) => {
        // if inspection creation has failed we need to clean up the state of files
        store.dispatch(files.actions.resetStore());
        store.dispatch(app.actions.setUpdatingInspection(false));

        throw error;
      },
    },
  }),
);

// QUESTION: currently the three actions below (updateInspection, updateInspectionFiles, updateDifferenceViewOptions) are all the same endpoint, however were being used as separate actions with different side effects
type UpdateInspectionPayload = [
  string, // inspection id
  UpdateInspectionData,
];
export const updateInspection = createSmartAction<RequestAction<ApiUpdateInspection>, UpdateInspectionPayload>(
  'inspections/updateInspection',
  (id, data) => ({
    request: {
      url: `/inspections/${id}`,
      method: 'PATCH',
      data,
    },
    meta: {
      asMutation: true,
      errorMessage: 'There was an error updating the inspection. Please try again.',
      onRequest: (request, _action, store) => {
        store.dispatch(app.actions.setAutosaving(false));
        const { input } = data;
        if (input) {
          setInspectionMarkups(input);
        }
        return request;
      },
    },
  }),
);

type UpdateInspectionFilesPayload = [
  string, // inspection Id
  UpdateInspectionData,
];
export const updateInspectionFiles = createSmartAction<
  RequestAction<ApiUpdateInspection>,
  UpdateInspectionFilesPayload
>('inspection/updateInspectionFiles', (id, data) => ({
  payload: {
    request: {
      url: `/inspections/${id}`,
      method: 'PATCH',
      data,
    },
  },
  meta: {
    asMutation: true,
    errorMessage: 'There was an error updating the inspection. Please try again.',
    onRequest: (request, _requestAction, store) => {
      store.dispatch(app.actions.setUpdatingInspection(false));
      return request;
    },
    onError: (error, _requestAction, store) => {
      if (error) {
        store.dispatch(fetchInspection(id, 'session'));
      }
      throw error;
    },
  },
}));

type UpdateDifferenceViewOptionsPayload = [
  Pick<UpdateInspectionData, 'sortOptions' | 'filterOptions'>,
  string, // inspection id
  string?, // request key
];
export const updateDifferenceViewOptions = createSmartAction<
  RequestAction<ApiUpdateInspection>,
  UpdateDifferenceViewOptionsPayload
>('inspections/updateDifferenceViewOptions', (data, id, requestKey) => ({
  request: {
    url: `/inspections/${id}`,
    method: 'PATCH',
    data,
  },
  meta: {
    asMutation: true,
    requestKey: requestKey || 'updateDifferenceViewOptions',
    errorMessage: 'There was an error updating state of differences. Please try again.',
    onSuccess: (response: QueryState<ApiUpdateInspection>, _action, store) => {
      if (data.filterOptions || data.sortOptions) {
        store.dispatch(inspection.actions.setUpdatedAt(response.data.updatedAt));
      }
      return response;
    },
  },
}));
