import { AxiosError, AxiosRequestConfig } from 'axios';
import { app } from 'store';
import { Store } from '@reduxjs/toolkit';

export function getBackendURL(path = '', version = 'v1') {
  const envUrl = import.meta.env.VITE_VERIFY_API_URL;
  let backendUrl = '';
  if (envUrl && process.env.NODE_ENV !== 'development') {
    // this is ok with string undefined because of how vars are injected in prod
    backendUrl = `https://${envUrl.replace('verify-web', 'verify-api').replace('\n', '')}`;
  }
  if (!path) {
    return `${backendUrl}/api/${version}/`;
  }
  return `${backendUrl}/api/${version}/${path}`;
}

export function appendToken(config: AxiosRequestConfig): AxiosRequestConfig {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers.Authorization = `Bearer ${window.accessToken}`;

  return config;
}

function onRequest(request: AxiosRequestConfig /* requestAction, store */) {
  // Get JWT token from Auth0 and set it in headers
  try {
    request.baseURL = getBackendURL();
    return appendToken(request);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return request;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onError(error: AxiosError<any>, requestAction: any, store: Store) {
  if (error?.response?.data.code === 403 && error?.response?.data?.message === 'Forbidden User') {
    window.location.replace('/error');
  }
  if (requestAction?.meta?.errorMessage) {
    store.dispatch(app.actions.setSnackMessage({ message: requestAction.meta.errorMessage, type: 'error' }));
  }

  throw error;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onSuccess(response: any, requestAction: any, store: Store) {
  if (requestAction?.meta?.successMessage) {
    store.dispatch(app.actions.setSnackMessage({ message: requestAction.meta.successMessage, type: 'success' }));
  }

  return response;
}

export { onRequest, onError, onSuccess };
