import { GVSnackMessage } from '../components/lib/GVSnackbar/GVSnackbar';
import { UserPreferences } from './myAccount';
import { Integration } from './integrations';

export interface AppState {
  loginAttempts: string[];
  snackMessage: GVSnackMessage;
  snackMessageQueue: Array<GVSnackMessage>;
  fetchingDifferences: boolean;
  autosaving: boolean;
  updatingInspection: boolean;
  startingInspection: boolean;
  resetIdleTimer: boolean;
  popUpNotifications: Array<{ type: string; dismissed: boolean }>;
  userPreferences: UserPreferences;
  toastStatuses: ToastStatuses;
  integrations: Integration[];
  displayName: string;
  showLiveText: boolean;
  focusedMatchIndex: number;
  showDifferenceBoxes: boolean;
  showOverlay: boolean;
}

export enum LeftPanelTabIndexes {
  prep = 0,
  result = 1,
  reports = 2,
}

export interface ToastStatuses {
  hasPanToolToastShown: boolean;
}
