import { useDispatch } from 'react-redux';
import axiosInstanceQueries from '../queriesAxios';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import app from 'store/app/app';

export interface CustomProfile {
  customProfileString?: string;
  customProfileName: string;
  orgId: string;
  fileName?: string;
  id: string;
}
export interface CreateCustomProfile {
  customProfileString: string;
  customProfileName: string;
  orgId: string;
  fileName?: string;
}
async function deleteCustomProfile(profileId: string): Promise<void> {
  await axiosInstanceQueries.delete(`/organizations/custom-profile/${profileId}`);
}

async function updateCustomProfile(profile: CustomProfile): Promise<void> {
  await axiosInstanceQueries.patch(`/organizations/custom-profile/${profile.id}`, {
    customProfileName: profile.customProfileName,
    customProfileString: profile.customProfileString,
    orgId: profile.orgId,
    fileName: profile.fileName,
  });
}

async function fetchCustomProfiles(orgId?: string): Promise<any> {
  if (orgId) {
    return axiosInstanceQueries.get(`/organizations/custom-profile/${orgId}`).then((response) => response.data);
  }
  return axiosInstanceQueries.get('/organizations/custom-profile').then((response) => response.data);
}

export const useCustomProfiles = (orgId?: string) => {
  const queryInfo = useQuery({
    queryKey: ['customProfiles', orgId],
    queryFn: () => fetchCustomProfiles(orgId),
    staleTime: 1000 * 60 * 5,
  });
  return {
    ...queryInfo,
    customProfilesList: queryInfo.data || [],
  };
};
async function uploadCustomProfile({ customProfileString, customProfileName, orgId, fileName }: CreateCustomProfile): Promise<void> {
  await axiosInstanceQueries.post('/organizations/custom-profile', {
    customProfileString,
    customProfileName,
    orgId,
    fileName,
  });
}

export const useDeleteCustomProfile = (): UseMutationResult<void, Error, string> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (profileData) => deleteCustomProfile(profileData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customProfiles'] });
      dispatch(app.actions.setSnackMessage({ message: 'Profile deleted successfully!', type: 'success' }));
    },
    onError: (error) => {
      dispatch(app.actions.setSnackMessage({ message: `Error deleting profile: ${error.message}`, type: 'error' }));
    },
  });
};

export const useUploadCustomProfile = (): UseMutationResult<void, Error, CreateCustomProfile> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (profileData) => uploadCustomProfile(profileData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customProfiles'] });
      dispatch(app.actions.setSnackMessage({ message: 'Profile uploaded successfully!', type: 'success' }));
    },
    onError: (error) => {
      dispatch(app.actions.setSnackMessage({ message: `Error uploading profile: ${error.message}`, type: 'error' }));
    },
  });
};

export const useUpdateCustomProfile = (): UseMutationResult<void, Error, CustomProfile > => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (profile) =>
      updateCustomProfile(profile),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customProfiles'] });
      dispatch(app.actions.setSnackMessage({ message: 'Profile updated successfully!', type: 'success' }));
    },
    onError: (error) => {
      dispatch(app.actions.setSnackMessage({ message: `Error updating profile: ${error.message}`, type: 'error' }));
    },
  });
};
