import WebViewerWrapper from 'pdftron/core/WebViewerWrapper';
import ToolsMixin from '../Tools';
import AnnotationMixin from 'pdftron/docManager/Annotation';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import GVAnnotationMixin from './utils/GVAnnotationMixin';
import { DocumentTypes, PDFTronTools, PolygonZoneCustomData } from 'types';
import Page from 'pdftron/core/Page';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';
import store from 'store/store';
import { inspection } from 'store';
import { getIframeDocument, setCursorStyle } from './utils/CropToolUtils';

class PolygonZone {
  source: WebViewerWrapper | null = null;
  target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get toolsMixin() {
    return new ToolsMixin(this.source, this.target);
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  createPolygonZoneAnnotation(instance: WebViewerInstance) {
    class PolygonZoneAnnotation extends GVAnnotationMixin(instance.Core.Annotations.PolygonAnnotation) {
      shouldDrawOverlay: boolean;
      constructor(shouldDrawOverlay = true) {
        super();
        this.setCustomData(PolygonZoneCustomData.polygonAnnotation, 'true');
        this.ToolName = PDFTronTools.POLYGON;
        this.IsHoverable = true;
        this.NoMove = true;
        this.NoResize = true;
        this.disableRotationControl();
        this.shouldDrawOverlay = shouldDrawOverlay; // only draw overlay for the first polygon on the page
      }

      get page() {
        return new Page(instance, DocumentTypes.source);
      }

      get pageSize() {
        return this.page.getPageSize(this.PageNumber);
      }

      get annotationPoints() {
        return this.getPath();
      }

      draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
        const pageSize = this.pageSize;
        const points = this.annotationPoints;
        this.setStyles(ctx, pageMatrix);
        // Step 1: draw the polygon
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
          ctx.lineTo(points[i].x, points[i].y);
        }
        ctx.closePath();
        ctx.fillStyle = 'white'; // Fill color for the polygon
        ctx.fill();

        // Step 2: Draw the overlay if shouldDrawOverlay is true
        if (this.shouldDrawOverlay) {
          // Step 3: Cover the entire canvas with a semi-transparent gray overlay
          ctx.fillStyle = 'rgba(128, 128, 128, 0.7)';
          ctx.fillRect(0, 0, pageSize.width, pageSize.height);
        }
        // Step 4: Set the composite operation to 'destination-out' to cut out the polygon area
        ctx.globalCompositeOperation = 'destination-out';

        // Step 5: Draw the polygon again, but this time it will cut through the overlay
        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
          ctx.lineTo(points[i].x, points[i].y);
        }
        ctx.closePath();
        ctx.fillStyle = 'white';
        ctx.fill();

        // Step 6: Reset the composite operation back to the default
        ctx.globalCompositeOperation = 'source-over';
      }
    }
    return PolygonZoneAnnotation;
  }

  drawPolygons(documentType: DocumentTypes, pagePoints: any) {
    if (!pagePoints) return;
    const documentWrapper = PDFManagerFactory.getViewer(documentType);
    if (!documentWrapper) return;
    const instanceSource = documentWrapper.instance;
    const manager = PDFManagerFactory.getPDFDocManager();
    if (!manager || !instanceSource) return;

    for (let i = 0; i < pagePoints.length; i++) {
      const polygonShapes = pagePoints[i];
      let isFirstPolygon = true;

      for (let j = 0; j < polygonShapes.length; j++) {
        const points = polygonShapes[j];

        const annotation = new (manager.createPolygonZoneAnnotation(instanceSource))(isFirstPolygon);

        for (let k = 0; k < points.length; k++) {
          annotation.addPathPoint(points[k][0], points[k][1]);
        }

        documentWrapper.annotationManager.addAnnotation(annotation);
        isFirstPolygon = false; // After the first polygon is drawn, don't draw more overlays
      }
    }

    instanceSource.Core.documentViewer.refreshAll();
    instanceSource.Core.documentViewer.updateView();
  }

  deleteAllPolygonZones(documentType: DocumentTypes) {
    store.dispatch(
      inspection.actions.setPolygons({
        documentType,
        points: '',
        type: '',
        key: '',
        layerOrSeparationName: '',
      }),
    );
  }

  getDeletePolygonPopup(instance: WebViewerInstance, documentType: DocumentTypes) {
    return {
      type: 'actionButton',
      img: '/icons/zoneDelete.svg',
      title: 'Delete',
      onClick: () => {
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
        instance.Core.annotationManager.deleteAnnotation(selectedAnnotation);

        // get all polygon annotations
        const allAnnotations = instance.Core.annotationManager.getAnnotationsList();
        const polygons = allAnnotations.filter((annotation) => {
          return annotation.getCustomData(PolygonZoneCustomData.polygonAnnotation) === 'true';
        });
        if (polygons.length === 0) {
          this.deleteAllPolygonZones(documentType);
        }
      },
    };
  }

  polygonZoneAnnotationSelected(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, _action: string) {
    const instance = documentType === DocumentTypes.source ? this.source?.instance : this.target?.instance;
    if (instance) {
      const popupButtons = [this.getDeletePolygonPopup(instance, documentType)];
      instance.UI.annotationPopup.update(popupButtons);
    }
    const wrapper = documentType === DocumentTypes.source ? this.source : this.target;
    if (!wrapper) return;
    const iframeDocument = getIframeDocument(wrapper, annotation.PageNumber);
    if (!iframeDocument) return;
    setCursorStyle('crosshairs', iframeDocument);
  }
}

export default PolygonZone;
