import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PolygonInactiveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8743_23102)">
          <path
            d="M1.875 14V14.125H2H9.67426H9.74236L9.77928 14.0678L11.1091 12.0074H14H14.125V11.8824V2V1.875H14H7.55581H7.48884L7.45174 1.93076L6.1221 3.92912H2H1.875V4.05412V14ZM10.7062 11.0938H10.6381L10.6011 11.151L9.27131 13.2115H2.76737V4.84265H6.53759H6.60443L6.64154 4.78706L7.97117 2.79559H13.2463V11.0938H10.7062Z"
            fill="white"
            stroke="white"
            stroke-width="0.25"
          />
          <path
            d="M11.5127 4.2H11.4627V4.25V4.91353V4.96353H11.5127H12.5127H12.5627V4.91353V4.25V4.2H12.5127H11.5127Z"
            fill="white"
            stroke="white"
            stroke-width="0.1"
          />
          <path
            d="M9.61279 4.2H9.56279V4.25V4.91353V4.96353H9.61279H10.6128H10.6628V4.91353V4.25V4.2H10.6128H9.61279Z"
            fill="white"
            stroke="white"
            stroke-width="0.1"
          />
          <path
            d="M7.7334 4.2H7.6834V4.25V4.91353V4.96353H7.7334H8.7334H8.7834V4.91353V4.25V4.2H8.7334H7.7334Z"
            fill="white"
            stroke="white"
            stroke-width="0.1"
          />
          <path
            d="M5.97949 11H5.92949V11.05V11.7136V11.7636H5.97949H6.97949H7.02949V11.7136V11.05V11H6.97949H5.97949Z"
            fill="white"
            stroke="white"
            stroke-width="0.1"
          />
          <path
            d="M4.08643 11H4.03643V11.05V11.7136V11.7636H4.08643H5.08643H5.13643V11.7136V11.05V11H5.08643H4.08643Z"
            fill="white"
            stroke="white"
            stroke-width="0.1"
          />
          <path
            d="M7.87256 11H7.82256V11.05V11.7136V11.7636H7.87256H8.87256H8.92256V11.7136V11.05V11H8.87256H7.87256Z"
            fill="white"
            stroke="white"
            stroke-width="0.1"
          />
        </g>
        <defs>
          <clipPath id="clip0_8743_23102">
            <rect width="12" height="12" fill="white" transform="translate(2 2)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default PolygonInactiveIcon;
