import { Popper } from '@mui/material';
import { DifferenceTypeNames, GridTypes } from 'types';
import { useSelector } from 'react-redux';
import { getFocusedDifference } from 'store';
import DifferenceGridLayout from './DifferenceGridLayout';
import BarcodeLayout from './BarcodeLayout';
import BrailleLayout from './BrailleLayout';

type DifferenceExpansionPanelProps = {
  anchorEl: React.RefObject<HTMLElement>;
};

const DifferenceExpansionPanel: React.FC<DifferenceExpansionPanelProps> = ({ anchorEl }) => {
  const currentDifference = useSelector(getFocusedDifference);

  const layoutComponents: Record<string, React.FC<{ id: string }>> = {
    [DifferenceTypeNames.Barcode]: BarcodeLayout,
    [DifferenceTypeNames.Braille]: BrailleLayout,
  };

  const LayoutComponent =
    currentDifference &&
    ((Object.values(GridTypes) as string[]).includes(currentDifference.type)
      ? DifferenceGridLayout
      : layoutComponents[currentDifference.type]);

  return (
    anchorEl.current && (
      <Popper open placement="bottom-end" anchorEl={anchorEl.current}>
        {LayoutComponent ? <LayoutComponent id={`${currentDifference?.id}-${currentDifference.type}`} /> : <div />}
      </Popper>
    )
  );
};

export default DifferenceExpansionPanel;
