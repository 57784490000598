import axiosInstanceQueries from '../queriesAxios';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import store from 'store/store';
import app from 'store/app/app';

export interface AutomationApiKey {
  orgId: string;
  encryptedKey: string;
  rawKey?: string;
  id: string;
  lastUsed: string;
  maskedKey: string;
  name: string;
  userId: string;
}

export interface AutomationApiFormInput {
  name: string;
}

async function fetchAutomationApiKeys(): Promise<AutomationApiKey[]> {
  return axiosInstanceQueries.get('/automation/apiKeys', {}).then((response) => response.data);
}

async function generateAutomationApiKey(data: AutomationApiFormInput): Promise<AutomationApiKey> {
  return axiosInstanceQueries
    .post('/automation/apiKeys', { ...data })
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

async function deleteAutomationApiKey(apiKeyId: string): Promise<void> {
  return axiosInstanceQueries
    .delete('/automation/apiKeys/' + apiKeyId)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

const queryKey = ['automationApiKey'];

export const useAutomationApiKey = () => {
  const queryClient = useQueryClient();
  const queryInfo = useQuery({
    queryKey,
    queryFn: fetchAutomationApiKeys,
  });

  const generateAutomationKeyMutation = useMutation({
    mutationFn: (data: AutomationApiFormInput) => generateAutomationApiKey(data),
    onSuccess: (response) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Automation API Key has been successfully created!',
          type: 'success',
        }),
      );
      return queryClient.invalidateQueries({ queryKey });
    },
    onError: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Failed to create Automation API, try again later',
          type: 'error',
        }),
      );
      return;
    },
  });

  const deleteAutomationKeyMutation = useMutation({
    mutationFn: (apiKeyId: string) => deleteAutomationApiKey(apiKeyId),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey });
    },
    onError: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Failed to delete Automation API key, try again later',
          type: 'error',
        }),
      );
    },
  });

  const prefetchAutomationApiKeys = async () => {
    await queryClient.prefetchQuery({
      queryKey,
      queryFn: fetchAutomationApiKeys,
      staleTime: 1000 * 60 * 5,
    });
  };

  return {
    ...queryInfo,
    automationApiKeys: queryInfo.data,
    generateAutomationKeyMutation,
    deleteAutomationKeyMutation,
    prefetchAutomationApiKeys,
  };
};
